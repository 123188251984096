.nav_search__form{
	&_item{
		padding: 8px 10px 8px 15px;
		background: #fff;
		width: 260px;
		border: 1px solid #f0f0f0;
		border-radius: 30px;
		display: flex;
		justify-content: space-between; 
		@include mq($from: mobile, $until: mobileVertical){
			width: 100%;
		}
		@include mq($from: tablet, $until: wide){
			width: 300px;
		}
	}
	&_input{
		display: block;
		font-size: 14px;
		line-height: 16px;
		color: #a4a4a4;
		font-weight: 300;
		background: transparent;
		border: none;
		outline: none;
		&:focus{
			color: #9a9898;
		}
	}
	input[type="text"]::-webkit-input-placeholder {
    	color: #a4a4a4;
   	} 
	&_submit{
		display: block;
		color: #a7a7a7;
		font-size: 16px;
		background: transparent;
		outline: none;
		cursor: pointer;
		border: none;
		background: transparent;
		transition: all .3s ease;
		&:hover{
            color: #86c60c;
        }
	}
}

.product-cart__quantity{
	display: flex;
	.quantity{
		display: flex;
		margin: 0 35px 0 0;
		@include mq($from: mobile, $until: wide){
			margin: 0 20px 0 0;
		}
		&__minus-btn, &__plus-btn{
		    width: 30px;
		    height: 45px;
		    line-height: 36px;
		    background: #fff;
		    border: 1px solid #dddddd;
		    font-size: 18px;
		    font-weight: 400;
		    color: #586077;
		    text-align: center;
		    white-space: nowrap;
		    outline: none;
		    cursor: pointer;
		    &:first-child {
			    border-radius: 5px 0 0 5px;
			}
			&:last-child {
			    border-radius: 0 5px 5px 0;
			}
		}
		&__input{
		    width: 40px;
		    height: 45px;
		    font-size: 14px;
		    text-align: center;
		    color: #586077;
		    font-weight: 500;
		    border-top: 1px solid #dddddd;
		    border-bottom: 1px solid #dddddd;
		    border-left: none;
		    border-right: none;
		    outline: none;
		}
	}
	.btn_add-cart{
		display: block;
		width: 165px;
		height: 45px;
	    padding: 10px;
	    text-decoration: none;
	    outline: none;
	    cursor: pointer;
	    background: #86c60c;
	    color: #ffffff;
	    font-size: 16px;
	    font-weight: 400;
	    text-transform: uppercase;
	    border: 2px solid #86c60c;
	    border-radius: 5px;
	    transition: all .3s;
	    i{

	    }
	    &:hover, &:focus{
			background: transparent;
			color: #86c60c; 
	    }
	}
}

.form-request{
	&__item{
		display: flex;
		align-items: center;
		margin: 0 0 20px 0;
		@include mq($from: mobile, $until: mobileHorizontal){
		 	margin: 0 0 15px 0;
		}

	}
	&__label{
		display: block;
		margin: 0;
		width: 150px;
		font-size: 14px;
		color: #313030;
		font-weight: 400;
		@include mq($from: mobile, $until: mobileVertical){
			margin: 0 0 4px 0;
		}
	}
	&__input, &__time{
		display: block;
		height: 40px;
		padding: 5px 10px;
		color: #838588;
		font-size: 14px;
		border-radius: 0;
 		border: 1px solid #e2e2e2;
		outline: none;
		background: transparent;
		font-weight: 300;
		transition: 0.3s ease;
		&:focus {
	      border: 1px solid #313030;
	      color: #313030;
	      font-weight: 300;
	    }
	    @include mq($from: mobile, $until: mobileHorizontal){
			font-size: 14px;
			padding: 8px;
		}
	}
	&__input{
		width: 350px;
	}
	&__time{
		width: 100px;
	}
	&__submit{
		.btn_request{
			display: block;
			padding: 5px 0;
			width: 165px;
			height: 40px;
			color: #ffffff;
			font-size: 18px;
			text-transform: uppercase;
			font-weight: 400;
			text-align: center;
			outline: none;
			cursor: pointer;
			background: #86c60d;
			border: none;
			border: 2px solid #86c60d;
			border-radius: 5px;
			transition: 0.3s;
			&:hover, &:focus{
	            color: #86c60d;
				background: #fff;
	        }
		}
	}
}
