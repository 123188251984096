.seo-text{
	padding: 50px 0;
	background: #f4f4f4;
	@include mq($from: mobile, $until: mobileVertical){
		padding: 30px 0;
	}
	p{
		margin: 0;
		font-size: 14px;
		line-height: 22px;
		font-weight: 300;
		color: #707070;
	}
}