.section-advantages{
	padding: 85px 0;
	@include mq($from: mobile, $until: wide){
		padding: 30px 0;
	}
	&__inner{
		display: flex;
		justify-content: space-between;
		@include mq($from: mobile, $until: mobileHorizontal){
			flex-direction: column;
		}
	}
	&__item{
		width: 33.3%;
		padding: 0 30px;
		@include mq($from: mobile, $until: mobileHorizontal){
			width: 100%;
			margin: 0 0 30px 0;
			padding: 0;
		}
		div{
			margin: 0 auto 25px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 140px;
			height: 140px;
			background: #a3db3a;
			border-radius: 50%;
			img{
				display: block;
			}
			@include mq($from: mobile, $until:wide){
				margin-bottom: 10px;
				width: 125px;
				height: 125px;
			}
		}
		h2{
			margin: 0 0 20px 0;
			font-weight: 500;
			font-size: 14px;
			color: #5b5b5b;
			text-align: center;
			text-transform: uppercase;
			@include mq($from: mobile, $until: wide){
				margin: 0 0 10px 0;
				font-size: 12px;
			}
		}
		p{
			margin: 0;
			font-size: 14px;
			font-weight: 300;
			line-height: 20px;
			text-align: center;
			color: #5b5b5b;
		}
	}
}