.sidebar-categories{
	background: #fafafa;
	border: 1px solid #d3d3d3;
	@include mq($from: mobile, $until: mobileHorizontal){
		margin: 0 0 20px 0;
		border: none;
		background: #fff;
	}
	&__title{
		padding: 10px 5px;
		border-bottom: 1px solid #e9e9e9;
		@include mq($from: mobile, $until: mobileHorizontal){
			display: none;
		}
		h3{
			margin: 0;
			font-weight: 500;
			font-size: 16px;
			color: #2f2f2f;
			text-transform: none;
			line-height: 18px;
		}
	}
}

.nav-categories{
	@include mq($from: desktop){
		position: relative;
		z-index: 999;
	}
	&__mobile-show{
		display: block;
		margin: 10px auto;
		width: 250px;
	    height: 40px;
	    border-radius: 5px;
	    font-weight: 500;
	    font-size: 14px;
	    text-align: center;
	    line-height: 42px;
	    text-transform: uppercase;
	    color: #fff;
	    background: #86c60c;
		@include mq($from: mobileHorizontal){
			display: none;
		}
	}
	&__inner{
		margin: 0;
		padding: 0;
		list-style: none;
		@include mq($from: mobile, $until: mobileHorizontal){
			display: none;
			&.show{
				display: block;
				border-bottom: 1px solid #015aac;
			}
		}
		@include mq($from: mobileHorizontal){
			display: block;
		}
	}
	&__list{
		&_second-level{
			margin: 0;
			padding: 0;
			min-width: 230px;
			list-style: none;
			background: #fafafa;
			@include mq($from: mobileHorizontal){
				position: absolute;
				z-index: 555;
				top: 0;
				left: 100%;
				border: 1px solid #cccccc;
			}
			.nav-categories__item{

			}
			.nav-categories__ln{
				padding: 10px 5px 10px 10px;
			}
		}
		&_third-level{
			margin: -1px 0 0 0;
			padding: 0;
			min-width: 230px;
			list-style: none;
			background: #fafafa;

			@include mq($from: mobile, $until: mobileHorizontal){
				padding-left: 20px;
			}
			@include mq($from: mobileHorizontal){
				position: absolute;
				top: 0;
				left: 100%;
				border: 1px solid #cccccc;
			}
			.nav-categories__item{

			}
			.nav-categories__ln{
				padding: 7px 5px;
				color: #363636;
				&:hover, &:focus {
					color: #fff; 
				}
				@include mq($from: mobile, $until: mobileHorizontal){
				}
				@include mq($from: mobileHorizontal){
					padding: 10px 15px 10px 15px;
				}
			}
		}
	}
	&__item{
		&_has-child{
			position: relative;
			> ul{
				display: none;
			}
			&:hover{
				> ul{
				display: block;
				}	
			}
		}
	}
	&__ln{
		display: block;
		padding: 7px 10px;
		font-size: 16px;
		line-height: 18px;
		font-weight: lighter;
		color: #363636;
		cursor: pointer;
		font-family: 'Open Sans Condensed', sans-serif;
		transition: .2s ease-in-out;
		@include mq($from: mobileHorizontal, $until: wide){
			font-size: 13px;
		}

		&:hover, &:focus{
			color: #fff;
			text-decoration: none;
			background: #86c60c;
		}
		i{
			margin: 0 0 0 5px;
			line-height: 10px;
			font-size: 14px;
		}
		// &.active{
		// 	color: #fff;
		// 	background: #86c60c;
		// }
	}
}
