.top-info{
	padding: 30px 0 15px 0;
	background: #fff;
	&__inner{
		@extend .container;
		display: flex;
		justify-content: space-between;
		@include mq($from: mobile, $until: mobileVertical){
			flex-direction: column;
			align-items: center;
		}
	}
	&__item{
		@include mq($from: mobile, $until: mobileVertical){
			margin: 0 0 15px 0;
		}
	}
}
.page-logo{
	img{
		display: block;
		@include mq($from: mobile, $until: mobileVertical){
			margin: 0 auto 15px;
		}
		@include mq($from: mobileVertical, $until: mobileHorizontal){
			width: 80%;
		}
	}
}
.top_contact{
	display: flex;
	align-items: center;
	&__phone{

	}
	&__data{
		@include mq($from: mobile, $until: desktop){
			display: none;
		}
	}
	&-img{
		display: flex;
		align-items: center;
    	justify-content: center;
    	margin: 0 13px 0 0;
		border-radius: 50%;
		width: 65px;
		height: 65px;
		border: 2px solid #eeeeee;
		border-radius: 2px solid #eeeeee;
		@include mq($from: mobile, $until: wide){
			display: none;
		}
		img{
			display: block;
		}
	}
	&-item{
		&__phone{
			display: block;
			margin: 0 0 5px 0;
			line-height: 18px;
			font-size: 18px;
			font-weight: 300;
			color: #363636;
			transition: .2s ease-in-out;
			@include mq($from: mobileVertical, $until: mobileHorizontal){
				font-size: 14px;
			}
			&:hover, &:focus{
				text-decoration: none;
				color: #0074af;
			}	
				
		}
		&__order{
			display: inline-block;
			color: #33b9ff;
			font-size: 14px;
			line-height: 16px;
			font-weight: 300;
			text-decoration: none;
			border-bottom: 1px dashed transparent;
			transition: .2s ease-in-out;
			&:hover, &:focus{
				text-decoration: none;
				color: #33b9ff;
				border-bottom: 1px dashed #33b9ff;
			}

		}
		&__data{
			margin: 0 0 5px 0;
			line-height: 18px;
			font-size: 18px;
			line-height: 16px;
			font-weight: 300;
			color: #363636;
		}
		&__calls{
			display: block;
			color: #363636;
			font-size: 14px;
			line-height: 16px;
			font-weight: 300;
		}
	}
}

.top_basket{
	@include mq($from: mobile, $until: mobileVertical){
		margin: 0 auto;
		width: 250px;
	}
	&__item{
		display: flex;
		border: 2px solid #eeeeee;
		border-radius: 30px;
		@include mq($from: mobileVertical, $until: mobileHorizontal){
			border: 1px solid #eeeeee;
			border-radius: 4px;
		}
	}
	&__ico{
		display: block;
		padding: 5px 15px;
		border-right: 1px solid #eeeeee;
		@include mq($from: mobileVertical, $until: mobileHorizontal){
			display: none;
		}
		img{
			display: block;
			margin: auto;
		}
	}
	&__inf{
		padding: 10px 15px;
		@include mq($from: mobileVertical, $until: mobileHorizontal){
			padding: 5px;
		}
		&-product{
			margin: 0;
			font-weight: 300;
			font-size: 14px;
			color: #444343;
			line-height: 20px;
			span{
				color: #86c60c;
				font-weight: 400;
			}
			@include mq($from: mobileVertical, $until: mobileHorizontal){
				font-size: 13px;
			}
		}
		&-sum{
			margin: 0;
			font-weight: 300;
			font-size: 14px;
			color: #444343;
			line-height: 20px;
			span{
				color: #4c4b4b;
				font-weight: 400;
			}
			@include mq($from: mobileVertical, $until: mobileHorizontal){
				font-size: 13px;
			}
		}
	}
}