.request-callback{
	.modal-title{
		margin: 0 0 40px;
		font-size: 24px;
		line-height: 24px;
		text-transform: none;
		font-weight: 400;
		text-align: left;
		color: #313030;
		@include mq($from: mobile, $until: mobileHorizontal){
			margin: 0 0 20px 0;
			font-size: 18px;
		}
	}
	.popup {
		max-width: 560px;
		min-height: 320px;
		padding: 30px;
		border-radius: 1px;
		@include mq($from: mobile, $until: mobileHorizontal){
			padding: 15px 10px;
		}
	}
}

.modal-basket{
	.modal-title{
		margin: 0 0 40px;
		font-size: 24px;
		line-height: 24px;
		text-transform: none;
		font-weight: 400;
		text-align: left;
		color: #313030;
		@include mq($from: mobile, $until: mobileHorizontal){
			margin: 0 0 20px 0;
			font-size: 18px;
		}
	}
	.popup {
		max-width: 950px;
		min-height: 320px;
		padding: 20px 35px;
		border-radius: 1px;
		@include mq($from: mobile, $until: mobileHorizontal){
			padding: 15px 25px;
		}
	}

	.basket-table{
		min-width: 100%;
		margin: 0;
		min-height: .01%;
		overflow-x: auto;
		table{
			width: 100%;
			max-width: 100%;
		}	
		thead{
			border-bottom: 2px solid #5ec700;
			tr{
				th{
					padding: 10px 10px;
					font-size: 14px;
					font-weight: 500;
					color: #000000;
					white-space: nowrap;
				}
			}
		}
		tbody{
			tr{
				border-bottom: 1px solid #cad3df;
				td{
					padding: 5px 15px;
					white-space: nowrap;
				}
			}
		}

		&__img{
			img{
				display: block;
			}
		}
		&__title{
			margin: 0;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: #3a3a3a;
			text-transform: uppercase;
			@include mq($from: mobile, $until: mobileHorizontal){
				font-size: 10px;
			}
		}
		&__trash{
			i{
				font-size: 16px;
				text-align: center;
				color: #cad3df;
				cursor: pointer;
				&:hover, &:focus{
					color: #b8b9ba;
				}
			}
		}
	}
	.basket-quantity{
		display: flex;
		margin: 0 35px 0 0;
		&__minus, &__plus{
			width: 30px;
		    height: 45px;
		    line-height: 36px;
		    background: #fff;
		    border: 1px solid #dddddd;
		    font-size: 18px;
		    font-weight: 400;
		    color: #586077;
		    text-align: center;
		    white-space: nowrap;
		    outline: none;
		    cursor: pointer;
		    @include mq($from: mobile, $until: mobileHorizontal){
				width: 25px;
				height: 30px;
				line-height: 25px;
				font-size: 16px;
			}
		    &:first-child {
			    border-radius: 5px 0 0 5px;
			}
			&:last-child {
			    border-radius: 0 5px 5px 0;
			}
		}
		&__input{
			width: 40px;
		    height: 45px;
		    font-size: 14px;
		    text-align: center;
		    color: #586077;
		    font-weight: 500;
		    border-top: 1px solid #dddddd;
		    border-bottom: 1px solid #dddddd;
		    border-left: none;
		    border-right: none;
		    outline: none;
		    @include mq($from: mobile, $until: mobileHorizontal){
				width: 20px;
				height: 30px;
				line-height: 25px;
			}
		}
		&__plus{

		}
	}
	.basket-info{
		display: flex;
		justify-content: space-between;
		margin: 0 0 15px 0;
		@include mq($from: mobile, $until: mobileVertical){
			flex-direction: column;
		}
		@include mq($from: mobileVertical, $until: mobileHorizontal){

		}
		&__left{
			padding: 10px 0 0 0;
			p{
				margin: 0;
				font-size: 14px;
				font-weight: 300;
				color: #888888;
				line-height: 22px;
				@include mq($from: mobile, $until: mobileHorizontal){
					font-size: 12px;
					line-height: 20px;
				}
			}
		}
		&__right{
			@include mq($from: mobile, $until: mobileVertical){
				display: flex;
				justify-content: flex-end;
				border-top: 1px solid #eceff4;;
			}
			table{
				background: #eceff4;
				border-radius: 0 0 5px 5px;
				tr{
					td{
						padding: 3px 15px;
						font-weight: 400;
						font-size: 16px;
						@include mq($from: mobile, $until: mobileHorizontal){
							padding: 3px 10px;
							font-size: 12px;
						}
					}
				}
			}
		}
	}
	.form-basket__submit{
		display: flex;
		justify-content: flex-end;
	}
	.form-basket__btn{
		display: block;
		width: 200px;
		height: 40px;
		line-height: 35px;
		border: 2px solid #86c60c;
		border-radius: 5px;
		background: #86c60c;
		color: #fff;
		font-weight: 400;
		font-size: 16px;
		text-align: center;
		outline: none;
		text-transform: uppercase;
		transition: .2s ease-in-out;
		&:hover, &:focus{
			background: #fff;
			color: #86c60c;
		}
	}
}