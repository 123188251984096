.page-main{


}
.page-wrap{
	padding: 30px 0;
	&__inner{
		display: flex;
		justify-content: space-between;
		@include mq($from: mobile, $until: mobileHorizontal){
			flex-direction: column;
		}
	}
	&-left{
		width: 25%;
		padding: 0 15px 0 0;
		@include mq($from: mobile, $until: mobileHorizontal){
			width: 100%;
			padding: 0;
		}
	}
	&-right{
		width: 75%;
		padding: 0 0 0 15px;
		@include mq($from: mobile, $until: mobileHorizontal){
			width: 100%;
			padding: 0;
		}
	}
}
.section-header{
	margin: 0 0 20px 0;
	h2{
		margin: 0;
		font-size: 18px;
		line-height: 20px;
		text-transform: uppercase;
		color: #4f4f4f;
		@include mq($from: mobile, $until: mobileVertical){
			font-size: 16px;
		}
	}
}
.part-img{
	margin: 0 0 25px 0;
	img{
		display: block;
		width: 100%;
	}
}
.part-txt{
	margin: 0 0 10px 0;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #4f4f4f;
	&__ln{
		color: #33b9ff;
		text-decoration: none;
		transition: .2s ease-in-aut;
		&:hover{
			color: #33b9ff;
			text-decoration: underline;
		}
	}
}