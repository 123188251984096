.product-bar{
	&__inner{
		margin: 0 0 35px 0;
		border-bottom: 3px solid #ffd700;
	}
	&__item{
		margin: 0 !important;
		position: relative;
		&.active{
			color: #3a3a3a;
			&:after{
				display: block;
			}
		}
		&:after{
			content: "";
			display: none;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -3px;
			width: 100%;
			border-bottom: 3px solid #86c60c !important;
		}

	}
	&__ln{
		padding: 10px !important;
		font-size: 18px;
		color: #a0a0a0;
		text-transform: uppercase;
		font-weight: 400;
		border: none !important;
		@include mq($from: mobile, $until: mobileVertical){
			font-size: 12px;
		}
		@include mq($from: mobileVertical, $until: wide){
			font-size: 16px;
		}
		&:hover, &:focus{
			background: none !important;
			color: #3a3a3a;
			outline: none;
		}
	}
	&__slider{
		
	}
}

.productbar-details{
	margin: 0 10px;
	outline: none;
	border: 2px solid #ffd700;
    transition: .2s ease-in-out;
    &:hover, &:focus{
    	border: 2px solid red;
    }
	&__img{
		margin: 0 0 5px 0;
		img{
			display: block;
			width: 100%;
		}
	}
	&__inf{
		padding: 15px 10px;
		@include mq($from: mobile, $until: wide){
			padding: 10px 5px;
		}
	}
	&__title{
		min-height: 60px;
		margin: 0 0 15px 0;
	    font-size: 14px;
	    line-height: 20px;
	    font-weight: 400;
	    color: #4f4f4f;
	    text-align: center;
	    text-transform: uppercase;
	    @include mq($from: mobile, $until: wide){
			margin: 0 0 10px 0;
			font-size: 12px;
		}
	}
	&__price{
		display: block;
	    margin: 0 0 20px 0;
	    font-size: 22px;
	    color: #3a3a3a;
	    line-height: 22px;
	    font-weight: 500;
	    text-align: center;
	    @include mq($from: mobile, $until: wide){
			margin: 0 0 10px 0;
			font-size: 18px;
		}
	}
	&__btn{
		display: block;
	    margin: 0 auto;
	    width: 165px;
	    height: 45px;
	    line-height: 40px;
	    border-radius: 5px;
	    border: 2px solid #86c60c;
	    background: #86c60c;
	    text-align: center;
	    color: #fff;
	    text-decoration: none;
	    font-size: 16px;
	    text-transform: uppercase;
	    font-weight: 500;
	    outline: none;
	    transition: .2s ease-in-out;
	    &:hover, &:focus{
	    	color: #86c60c;
		    background: #fff;
		    text-decoration: none;
	    }
	    @include mq($from: mobile, $until: wide){
			width: 140px;
			height: 35px;
			line-height: 30px;
			font-size: 14px;
			font-weight: 400;
		}
	}
}


.product-bar__slider{
	.slick-arrow{
        display: block;
        position: absolute;
        top: -75px;
        padding: 0;
        margin: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 0;
        line-height: 0;
        border: 0;
        z-index: 100; 
        outline: none;
    }
    .slick-prev{
        right: 40px;
        cursor: pointer;
        background: #ffd700 url(../img/ar-l.png) no-repeat center;
		&:hover{
			background: #86c60c url(../img/ar-l.png) no-repeat center;;
		}
    }
    .slick-next{
        right:0;
        cursor: pointer;
		background: #ffd700 url(../img/ar-r.png) no-repeat center;
    	&:hover{
    		background: #86c60c url(../img/ar-r.png) no-repeat center;
    	}
    }
}