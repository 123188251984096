.section-order{
	margin: 0 0 50px 0;
	.section-title{
		padding: 30px 0 0 0;
		h2{
			margin: 0;
		    font-size: 18px;
		    line-height: 20px;
		    text-transform: uppercase;
		    color: #4f4f4f;
		}
	}
}

.order-form{
	.order_list{
		&__item{
			display: flex;
			align-items: center;
			margin: 0 0 30px 0;
			@include mq($from: mobile, $until: mobileHorizontal){
				flex-direction: column;
				align-items: flex-start;
			}
		}
		&__label{
			margin: 0;
			width: 220px;
			font-size: 14px;
			color: #595959;
			line-height: 16px;
			font-weight: 400;
			span{
				color: #ef0000;
			}
			@include mq($from: mobile, $until: mobileHorizontal){
				width: 100%;
				margin: 0 0 10px 0;
			}
		}
		&__input{
			display: block;
			width: 360px;
			height: 40px;
			padding: 10px;
			font-size: 14px;
			line-height: 1.42857143;
			color: #999999;
			background: #fff;
			border: 1px solid #d3d3d3;
			border-radius: 0;
			outline: none;
			&:focus{
				color: #0085c3;
				outline: none;
				border: 1px solid #0085c3;
			}
			@include mq($from: mobile, $until: mobileVertical){
				width: 290px;
			}
		}
		&__textarea{
			display: block;
			width: 360px;
			height: 160px;
			padding: 10px;
			font-size: 14px;
			line-height: 1.42857143;
			color: #999999;
			background: #fff;
			border: 1px solid #d3d3d3;
			border-radius: 0;
			outline: none;
			resize: none;
			@include mq($from: mobile, $until: mobileVertical){
				width: 290px;
			}
		}
	}	
	&__submit{
		text-align: right;
	}
	&__btn{
		text-align: center;
		outline: none;
		cursor: pointer;
		text-transform: uppercase;
		width: 200px;
		height: 40px;
		line-height: 38px;
		background: #86c60c;
		color: #ffffff;
		font-size: 16px;
		font-weight: 400;
		text-transform: uppercase;
		border: 2px solid #86c60c;
		border-radius: 5px;
		outline: none;
		transition: 0.2s ease-in-out;
		&:hover, &:focus{
			background: #fff;
			color: #86c60c;
		}
	}
}

.order_consist{

}
.order-table{
	min-width: 100%;
	margin: 0;
	min-height: .01%;
	overflow-x: auto;
	table{
		width: 100%;
		max-width: 100%;
		thead{
			border-bottom: 2px solid #5ec700;
			tr{
				th{
					padding: 10px 5px;
					font-size: 14px;
					font-weight: 500;
					color: #000000;
				}
			}
		}
		tbody{
			tr{
				border-bottom: 1px solid #cad3df;
				td{
					padding: 10px 20px;
					white-space: nowrap;
				}
			}
		}
	}	


	&__img{
		img{
			display: block;
		}
	}
	&__title{
		margin: 0;
		font-size: 16px;
		font-weight: 400;
		color: #3a3a3a;
		text-transform: uppercase;
		@include mq($from: mobile, $until: mobileHorizontal){
			font-size: 12px;
		}
	}
	&__quantity{
		display: block;
		font-weight: 400;
		font-size: 14px;
		color: #616161;
		@include mq($from: mobile, $until: mobileHorizontal){
			font-size: 12px;
		}
	}
	&__price{
		display: block;
		font-size: 18px;
		color: #000000;
		text-align: left;
		font-weight: 500;
		@include mq($from: mobile, $until: mobileHorizontal){
			font-size: 14px;
		}
	}
}

.amount-calc{
	display: flex;
    justify-content: flex-end;
    margin: 0 0 30px 0;
	table{
		background: #eceff4;
		border-radius: 0 0 5px 5px;
		tr{
			td{
				padding: 5px 15px;
				font-weight: 400;
				font-size: 16px;
				@include mq($from: mobile, $until: mobileHorizontal){
					padding: 3px 10px;
					font-size: 12px;
				}
			}
		}
	}
}