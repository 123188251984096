.page-head{
	padding: 15px 0;
	background: #363636;
	&__inner{
		@extend .container;
		display: flex;
		justify-content: space-between;
		@include mq($from: mobile, $until: mobileVertical){
			justify-content: flex-end;
		}
		@include mq($from: mobileVertical, $until: mobileHorizontal){
			align-items: center;
		}
	}
	&__item{

	}
	&__adress{
		display: flex;
		@include mq($from: mobile, $until: mobileVertical){
			display: none;
		}
		@include mq($from: mobileVertical, $until: mobileHorizontal){
			flex-direction: column;
		}
		p{
			margin: 0 20px 0 0;
			font-size: 14px;
			font-weight: 300;
			color: #fff;

			i{
				margin: 0 5px 0 0;
				font-size: 18px;
			}
			&:last-child{
				margin: 0;
			}
			@include mq($from: mobileVertical, $until: mobileHorizontal){
				margin: 0 0 5px 0;
			}
		}
	}
}

.head_login{
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;
	li{
		padding: 0 10px;
		border-right: 1px solid #fff;
		&:last-child{
			border-right: none;		
		}
		a{
			display: block;
			font-size: 14px;
			font-weight: 300;
			color: #fff;
			text-decoration: none;
			transition: .3s ease-in-out;
			&:hover, &:focus{
				text-decoration: none;
				color: #86c60c;
			}
		}
	}
}