.order_list__item{

  .custom-select-menu {
    display: inline-block;
    outline: none;
    position: relative;
    label {
      display: block;
      margin: 0;
      position: relative; 
      user-select: none;
      width: 360px;
      height: 40px;
      padding: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #afafaf;
      cursor: pointer;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d3d3d3;
      border-radius: 0;
       &:after {
        content: '';
        position: absolute;
        top: 50%; 
        right: 15px;
        margin-top: -3px;
        width: 8px; 
        height: 8px;
        border-bottom: 1px solid #464646;
        border-right: 1px solid #464646;
        transform: rotate(45deg) translateY(-50%);
        transition: all .4s ease-in-out;
        transform-origin: 50% 0;
      }
      &:focus{
        color: #0085c3;
        outline: none;
        border: 1px solid #0085c3;
        box-shadow: 0 2px 3px rgba(58, 132, 223, 0.3);
      }
      @include mq($from: mobile, $until: mobileVertical){
        width: 290px; 
        margin: auto; 
      }
    }
  } 

  .custom-select-menu label.opened{
    border-radius: 0;
  }
  .custom-select-menu label.opened:after{
    margin-top: 3px;
    transform: rotate(-135deg) translateY(-50%);
  }

  .custom-select-menu ul {
    margin: -5px 0 0 0;
    padding: 0;
    position: absolute;
    top: 45px;
    left: 0;
    z-index: 4;
    width: 100%;
    background: #fff;
    border-left: 1px solid #d3d3d3;
    border-right: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    border-radius: 0;
    list-style: none;
    overflow-x: hidden;
    overflow-y: scroll;
    li {
        cursor: pointer;
        padding: 10px;
        font-size: 14px;
        color: #777777;
        background: #fff;
        text-align: left;
        &:hover, &:focus{
          background: #5ec700;
          color: #fff;
        }
      }   
  }

} 