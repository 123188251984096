.breadcrumbs{
	position: relative;
	top: 15px;
	&__inner{
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		li{
			margin: 0 20px 0 0;
			padding: 0;
			&:last-child{
				margin: 0;
			}
			a{
				display: block;
				position: relative;
				text-decoration: none;
				color: #3f3f3f;
				font-size: 14px;
				&:after{
					content: "/";
					position: absolute;
					top: 2px;
					right: -10px;
					font-size: 14px;
					color: #3f3f3f;
				}
				&:hover, &:focus{
					color: #649530;
					text-decoration: underline;
					&:after{
						color: #649530;
					}
				}
			}
			span{
				display: block;
				color: #649530;
				font-size: 14px;
			}
		}
	}
}
