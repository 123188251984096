@import "helpers/variables";

@import "base/fonts";

@import "helpers/mq";

@import "base/base";


/*
* — COMPONENTS —
*/
@import "components/typography";
@import "components/form";
@import "components/buttons";
@import "components/breadcrumbs";

/*
* — HELPERS —
*/




/*
* — LAYOUT —
*/
@import "layout/header";
@import "layout/section";
@import "layout/footer";
@import "layout/pagination";


/*
* — PART —
*/
@import "part/top-info";
@import "part/top-nav";
@import "part/product-bar";
@import "part/section-advantages";
@import "part/sidebar-categories";
@import "part/content-slider";
@import "part/section-contact";
@import "part/product-cart";
@import "part/section-order";
@import "part/section-catolog";

@import "part/seo-text";
@import "part/modal-view";


/*
* — PLUGINS —
*/

@import "plugins/slick";
@import "plugins/select";
@import "plugins/modal";