.pagination{
	display: block;
	padding: 25px 0;
	margin: 0;
	@include mq($from: mobile, $until: mobileVertical){
		padding: 10px 0;
	}
	&-inner{
		display: flex;
		justify-content: flex-end; 
		align-items: center; 
		margin: 0;
		padding: 0;
		list-style: none;
		li{
			margin: 0 15px 0 0;
			&:last-child{
				margin: 0;
			}
		}
	}
}
.pagination_first{

}
.pagination_list{
	@include mq($from: mobile, $until: mobileVertical){
		display: none;
	}
}
.pagination_last{

}
.page-numbers{
	display: block;
	text-decoration: none;
	font-size: 14px;
	font-weight: 400;
	color: #54657e;
	&:hover, &:focus{
		text-decoration: none;
	}
}
.page-numbers__item{
	width: 25px;
	height: 25px;
	border: 2px solid transparent;
	text-align: center;
    line-height: 22px;
	&:hover, &:focus{
		border: 2px solid #86c60c;
	}
}
.page-numbers__last{
	&:hover, &:focus{
		color: #86c60c;
	}
}
.first, .next{
	color: #54657e;
	&:hover, &:focus{
		color: #86c60c;
	}
}
.first{
	margin: 0 10px 0 0;
}
.next{
	margin: 0 0 0 10px;
}
.current{
	width: 25px;
	height: 25px;
	border: 2px solid #86c60c;
	text-align: center;
    line-height: 22px;
}