.section-contact{
	&__inner{
		display: flex;
		justify-content: space-between;
		margin: 0 0 50px 0;
		@include mq($from: mobile, $until: mobileVertical){
			flex-direction: column;
			margin: 0 0 40px 0;
		}
	}
	&__item{
		@include mq($from: mobile, $until: mobileVertical){
			margin: 0 0 20px 0;
		}
		h3{
			margin: 0 0 15px 0;
			font-size: 16px;
			color: #151219;
			font-weight: 500;
			@include mq($from: mobile, $until: wide){
				margin: 0 0 10px 0;
			}
		}
		span{
			display: inline-block;
			margin: 0 0 10px 0;
			font-size: 16px;
			line-height: 18px;
			color: #00aaff;
			///ont-family: 'Open Sans Condensed', sans-serif;
			border-bottom: 1px dashed #00aaff;
			@include mq($from: mobile, $until: wide){
				margin: 0 0 5px 0;
				font-size: 14px;
			}
		}
		a{
			display: block;
			margin: 0 0 10px 0;
			font-size: 16px;
			line-height: 18px;
			color: #4f4f4f;
			cursor: pointer;
			text-decoration: none;
			transition: .2s ease-in-out;
			@include mq($from: mobile, $until: wide){
				margin: 0 0 5px 0;
				font-size: 14px;
			}
			&:hover, &:focus{
				color: #00aaff;
				text-decoration: none;
			}
		}
		p{
			margin: 0 0 10px 0;
			font-size: 16px;
			line-height: 18px;
			color: #4f4f4f;
			@include mq($from: mobile, $until: wide){
				margin: 0 0 5px 0;
				font-size: 14px;
			}
		}
	}
}
.contact-map{
	#map {
	    width: 100% !important;
	    height: 400px !important;
	    border: none;
	    @include mq($from: mobile, $until: mobileHorizontal){
			height: 250px !important;
		}
	}
}