html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body{
	margin: 0;
	padding: 0;
	min-width: 320px;
	color: #4e4e4e;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	line-height: 20px;
}


.container{
	max-width: 1170px;
	padding-left: 15px;
	padding-right: 15px;
	margin: 0 auto;
}


@include mq($from: mobile, $until: mobileVertical){

}
@include mq($from: mobileVertical, $until: mobileHorizontal){

}
@include mq($from: tablet, $until: desktop){

}
@include mq($from: desktop, $until: wide){
	
}
