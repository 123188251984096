.product-cart{
	display: flex;
	@include mq($from: mobile, $until: mobileVertical){
		flex-direction: column;
		margin: 0 0 25px 0;
	}
	&__left{
		width: 40%;
		@include mq($from: mobile, $until: mobileVertical){
			width: 100%;
		}
	}
	&__right{
		padding: 0 0 0 50px;
		width: 60%;
		@include mq($from: mobile, $until: mobileVertical){
			width: 100%;
			padding: 0;
		}
		@include mq($from: mobileVertical, $until: mobileHorizontal){
			padding: 0 0 0 15px;
		}
		@include mq($from: tablet, $until: wide){
			padding: 0 0 0 20px;
		}
	}
}

.product-detail__images{
	height: 550px;
	margin: 0 0 30px 0;
	@include mq($from: mobile, $until: mobileVertical){
		height: 430px;
	}
	@include mq($from: mobileVertical, $until: wide){
		width: 100%;
		height: 350px;
		margin: 0 auto 20px;
	}
	.detail-img__top{
		margin: 0 0 20px 0;
		@include mq($from: mobile, $until: wide){
			margin: 0 0 10px 0;
		}
		.image-big{
			height: 350px;
			border: 2px solid #d3d3d3;
			@include mq($from: mobile, $until: mobileVertical){
				height: 350px;
			}
			@include mq($from: mobileVertical, $until: mobileHorizontal){
				height: 225px;
			}
			@include mq($from: tablet, $until: wide){
				height: 280px;
			}
			img{
				display: block;
				width: 100%;
			}
		}
	}
	.detail-img__bottom{
		margin: 0;
		display: flex;
		justify-content: space-between; 
		align-items: center;
		.image-small{
			width: 80px;
			height: 80px;
			border: 2px solid #d3d3d3;
			@include mq($from: mobileVertical, $until: wide){
				width: 65px;
				height: 65px;
			}	
			img{
				display: block;
				width: 100%;
			}
		}
	}
	.slick-arrow{
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
        margin: 0;
        width: 30px;
        height: 30px;
        font-size: 0;
        line-height: 0;
        border: 0;
        z-index: 100; 
        outline: none;
        border: 1px solid #000;
    }
    .slick-prev{
        left: -19px;
        background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAMCAMAAACZS1ciAAAAG1BMVEUqKioqKioqKioqKioqKioqKioqKioqKioqKirBokQfAAAACHRSTlMAEXeImaq73QoLrHMAAAAiSURBVHgBYwABRjZWCMXBjItiYOFgg6hl52AizGBjgDAYASYQAL87LcOfAAAAAElFTkSuQmCC) no-repeat center;
        &:hover, &:active{
        	background: #00c9fb url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAMCAMAAACZS1ciAAAAG1BMVEX///////////////////////////////////8HBRbfAAAACHRSTlMAEXeImaq73QoLrHMAAAAiSURBVHgBYwABRjZWCMXBjItiYOFgg6hl52AizGBjgDAYASYQAL87LcOfAAAAAElFTkSuQmCC)no-repeat center;
        	border: 1px solid #00c9fb;
        }
    }
    .slick-next{
		right: 7px;
		background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAMCAMAAACZS1ciAAAAIVBMVEX///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADdcGRXAAAACnRSTlMAESJViJmqu8zu53CBGAAAACNJREFUCNdjYGFjAAM2LsIMdi5WEMXMxcmEi2LggFAMjBAKAC51AO+YrRq7AAAAAElFTkSuQmCC) no-repeat center;
    	 &:hover, &:active{
        	background: #00c9fb url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAMCAMAAACZS1ciAAAAIVBMVEX///////////////////////////////////////////9/gMdvAAAACnRSTlMAESJViJmqu8zu53CBGAAAACNJREFUCNdjYGFjAAM2LsIMdi5WEMXMxcmEi2LggFAMjBAKAC51AO+YrRq7AAAAAElFTkSuQmCC) no-repeat center;
        	border: 1px solid #00c9fb;
        }
        @include mq($from: mobile, $until: mobileHorizontal){
			right: -10px;
		}
    }
}


.product-cart{
	&__title{
		margin: 0 0 15px 0;
		h2{
			margin: 0;
			font-size: 18px;
			font-weight: 400;
			line-height: 22px;
			color: #4f4f4f;
			text-transform: uppercase;
			@include mq($from: mobile, $until: wide){
				font-size: 16px;
				line-height: 20px;
			}
		}
	}
	&__reference{
		display: block;
		margin: 0 0 20px 0;
		font-weight: 400;
		font-size: 14px;
		color: #565656;
		line-height: 16px;
		@include mq($from: mobile, $until: wide){
			margin: 0 0 15px 0;
			font-size: 14px;
		}
	}
	&__availability{
		display: block;
		position: relative;
		margin: 0 0 20px 0;
		padding: 0 0 0 15px;
		font-weight: 400;
		font-size: 16px;
		color: #86c60c;
		line-height: 16px;
		&:before{
			content: "";
			position: absolute;
			top: 2px;
			left: 0;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: #86c60c;
		}
		@include mq($from: mobile, $until: wide){
			margin: 0 0 15px 0;
			font-size: 14px;
		}
	}
	&__price{
		display: block;
		margin: 0 0 30px 0;
		font-size: 24px;
		font-weight: 400;
		color: #1b2943;
		line-height: 26px;
		@include mq($from: mobile, $until: wide){
			margin: 0 0 20px 0;
			font-size: 20px;
		}
	}
}





.product-information{
	margin: 0 0 50px 0;
	&__inner{
		border: none;
	}
	&__item{
		margin-bottom: -2px !important;
		background: #fff;
	}
	&__ln{
		margin: 0 !important;
		color: #86c60c !important;
		font-size: 16px;
		font-weight: 400 !important;
		text-transform: uppercase;
		border: 2px solid #e9e9e9 !important;
		border-bottom-color: transparent !important;
		border-radius: 0 !important;
	}
	&__tab{
		padding: 15px;
		border: 2px solid #e9e9e9;
		&-1{
			p{
				margin: 0 0 25px 0;
				font-size: 14px;
				color: #1b2943;
				font-weight: 400;
				line-height: 22px;
			}
			h2{
				margin: 0 0 20px;
				font-weight: 500;
				font-size: 16px;
				color: #1b2943;
			}
			ul{
				margin: 0;
				padding: 0 0 0 10px;
				list-style: none;
				li{
					position: relative;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					color: #1b2943;
					&:before{
						content: "";
						position: absolute;
						top: 10px;
						left: -8px;
						width: 3px;
						height: 3px;
						background: #1b2943;
						border-radius: 50%;
					}
				}
			}
		}
	}
}