.overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  position: fixed; 
  cursor: default; 
  transition: all 0.6s;
  &:target {
    visibility: visible;
    opacity: 1;
  }
}
.modal-title{
  margin: 0 0 40px;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
  color: #313030;
  @include mq($from: mobile, $until: mobileHorizontal){
    margin: 0 0 20px 0;
    font-size: 18px;
  }
}

/* стили модального окна */
.popup {
  top: 0;
  right: 0;
  left: 50%;
  font-size: 14px;
  z-index: 10000;
  width: 100%;
  max-width: 520px;
  min-height: 320px;
  position: fixed;
  padding: 30px 60px;
  border: 1px solid #383838;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 15px 20px rgba(0,0,0,.22),0 19px 60px rgba(0,0,0,.3);
  transform: translate(-50%, -150%);
  transition: transform 0.6s ease-out;
  
  @include mq($from: mobile, $until: mobileVertical){
      width: 90%;
      padding: 30px 10px;
      border-radius: 0;
  }

  @include mq($from: mobileVertical, $until: mobileHorizontal){
    width: 90%;
    padding: 30px 10px;
    border-radius: 0;
    overflow-x: auto;
  }
  @include mq($from: tablet, $until: desktop){
    width: 90%;
  }
}

.overlay:target+.popup {
  top: 50%;
  transform: translate(-50%, -50%);
}

/* формируем кнопку закрытия */
.close {
  position: absolute;
  padding: 0;
  top: 2px;
  right: 5px;
  width: 18px;
  height: 24px;
  border: none;
  border-radius: none;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  line-height: 28px;
  opacity: 1;
  transition: background-color ease .7s;
  @include mq($from: mobile, $until: mobileVertical){
    width: 25px;
    height: 25px;
    line-height: 15px;
    top: 5px;
    right: 5px;
    border-radius: 0;
  }
  &:before{
    color: #363636;
    content: '\2715';
    font-size: 20px;
    font-weight: 700;
    @include mq($from: mobile, $until: mobileVertical){
      font-size: 18px;
    }
  }
  &:hover {
    opacity: 1;
    &:before{
      color: #86c60d;
    }
  }
}




