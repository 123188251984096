.top-nav{
	position: relative;
	padding: 10px 0;
	background: #fafafa;
	border-top: 1px solid #dce8ee;
	box-shadow: 0px 4px 6px 0px rgba(1, 2, 2, 0.2);
	@include mq($from: mobile, $until: mobileHorizontal){
		height: 45px;
	}
	&__inner{
		@extend .container;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.btn-navbar{
	display: none;
	position: absolute;
	width: 40px;
	height: 40px;
	padding: 0;
	color: #393939;
	font-size: 32px;
	line-height: 20px;
	border: none;
	background: transparent;
	@include mq($from: mobile, $until: mobileHorizontal){
		display: block;
		top: 0px;
    	right: 10px;
	}
}
.nav-list{
	margin: 0;
	@include mq($from: mobile, $until: mobileHorizontal){
    	text-align: right;
	}
	@include mq($from: desktop){
		padding: 10px 0;
	}
	&__inner{
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
		&.active{
			display: block;
			position: absolute;
			padding-left: 15px;
			padding-right: 15px;
			left: 0;
			right: 0;
			z-index: 44;
			background: #fff;
			border-bottom: 2px solid #ae782f;
			@include mq($from: mobile, $until: mobileHorizontal){
				top: 75px;
			}
		}
		@include mq($from: mobile, $until: mobileHorizontal){
			display: none;
			text-align: left;
			width: 100%;
		}
		li{
			margin: 0 45px 0 0;
			&:last-child{
				margin: 0;
			}
			@include mq($from: mobile, $until: mobileHorizontal){
				display: block;
				margin: 0;
				padding: 8px 0;
				background: #fff;
			}
			@include mq($from: mobileHorizontal, $until: wide){
				margin: 0 20px 0 0;
			}
			a{
				display: block;
				text-decoration: none;
				text-transform: uppercase;
				font-weight: 400;
				font-size: 14px;
				color: #4a4a4a;
				transition: .2s ease-in-out;
				@include mq($from: mobileHorizontal, $until: wide){
					font-size: 12px;
				}
				&:hover, &:focus{
					color: #86c60c;
					text-decoration: none;
				}
			}
		}
	}
	&_mobile{
		position: relative;
		z-index: 1000;
		.header-nav__inner{
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			@include mq($from: mobile, $until: mobileHorizontal){
				display: block;
			}
			@include mq($from: tablet, $until: desktop){
				display: flex;
			}
		}
	}
}

.nav_search{
	@include mq($from: mobile, $until: desktop){
		display: none;
	}
}