@charset "UTF-8";
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  color: #4e4e4e;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 20px; }

.container, .page-head__inner, .footer_main__inner, .footer_content__inner, .top-info__inner, .top-nav__inner {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto; }

/*
* — COMPONENTS —
*/
hr {
  margin: 0 0 30px 0; }

.nav_search__form_item {
  padding: 8px 10px 8px 15px;
  background: #fff;
  width: 260px;
  border: 1px solid #f0f0f0;
  border-radius: 30px;
  display: flex;
  justify-content: space-between; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .nav_search__form_item {
      width: 100%; } }
  @media (min-width: 46.25em) and (max-width: 81.24em) {
    .nav_search__form_item {
      width: 300px; } }

.nav_search__form_input {
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: #a4a4a4;
  font-weight: 300;
  background: transparent;
  border: none;
  outline: none; }
  .nav_search__form_input:focus {
    color: #9a9898; }

.nav_search__form input[type="text"]::-webkit-input-placeholder {
  color: #a4a4a4; }

.nav_search__form_submit {
  display: block;
  color: #a7a7a7;
  font-size: 16px;
  background: transparent;
  outline: none;
  cursor: pointer;
  border: none;
  background: transparent;
  transition: all .3s ease; }
  .nav_search__form_submit:hover {
    color: #86c60c; }

.product-cart__quantity {
  display: flex; }
  .product-cart__quantity .quantity {
    display: flex;
    margin: 0 35px 0 0; }
    @media (min-width: 20em) and (max-width: 81.24em) {
      .product-cart__quantity .quantity {
        margin: 0 20px 0 0; } }
    .product-cart__quantity .quantity__minus-btn, .product-cart__quantity .quantity__plus-btn {
      width: 30px;
      height: 45px;
      line-height: 36px;
      background: #fff;
      border: 1px solid #dddddd;
      font-size: 18px;
      font-weight: 400;
      color: #586077;
      text-align: center;
      white-space: nowrap;
      outline: none;
      cursor: pointer; }
      .product-cart__quantity .quantity__minus-btn:first-child, .product-cart__quantity .quantity__plus-btn:first-child {
        border-radius: 5px 0 0 5px; }
      .product-cart__quantity .quantity__minus-btn:last-child, .product-cart__quantity .quantity__plus-btn:last-child {
        border-radius: 0 5px 5px 0; }
    .product-cart__quantity .quantity__input {
      width: 40px;
      height: 45px;
      font-size: 14px;
      text-align: center;
      color: #586077;
      font-weight: 500;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      border-left: none;
      border-right: none;
      outline: none; }
  .product-cart__quantity .btn_add-cart {
    display: block;
    width: 165px;
    height: 45px;
    padding: 10px;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    background: #86c60c;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    border: 2px solid #86c60c;
    border-radius: 5px;
    transition: all .3s; }
    .product-cart__quantity .btn_add-cart:hover, .product-cart__quantity .btn_add-cart:focus {
      background: transparent;
      color: #86c60c; }

.form-request__item {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .form-request__item {
      margin: 0 0 15px 0; } }

.form-request__label {
  display: block;
  margin: 0;
  width: 150px;
  font-size: 14px;
  color: #313030;
  font-weight: 400; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .form-request__label {
      margin: 0 0 4px 0; } }

.form-request__input, .form-request__time {
  display: block;
  height: 40px;
  padding: 5px 10px;
  color: #838588;
  font-size: 14px;
  border-radius: 0;
  border: 1px solid #e2e2e2;
  outline: none;
  background: transparent;
  font-weight: 300;
  transition: 0.3s ease; }
  .form-request__input:focus, .form-request__time:focus {
    border: 1px solid #313030;
    color: #313030;
    font-weight: 300; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .form-request__input, .form-request__time {
      font-size: 14px;
      padding: 8px; } }

.form-request__input {
  width: 350px; }

.form-request__time {
  width: 100px; }

.form-request__submit .btn_request {
  display: block;
  padding: 5px 0;
  width: 165px;
  height: 40px;
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
  outline: none;
  cursor: pointer;
  background: #86c60d;
  border: none;
  border: 2px solid #86c60d;
  border-radius: 5px;
  transition: 0.3s; }
  .form-request__submit .btn_request:hover, .form-request__submit .btn_request:focus {
    color: #86c60d;
    background: #fff; }

.breadcrumbs {
  position: relative;
  top: 15px; }
  .breadcrumbs__inner {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex; }
    .breadcrumbs__inner li {
      margin: 0 20px 0 0;
      padding: 0; }
      .breadcrumbs__inner li:last-child {
        margin: 0; }
      .breadcrumbs__inner li a {
        display: block;
        position: relative;
        text-decoration: none;
        color: #3f3f3f;
        font-size: 14px; }
        .breadcrumbs__inner li a:after {
          content: "/";
          position: absolute;
          top: 2px;
          right: -10px;
          font-size: 14px;
          color: #3f3f3f; }
        .breadcrumbs__inner li a:hover, .breadcrumbs__inner li a:focus {
          color: #649530;
          text-decoration: underline; }
          .breadcrumbs__inner li a:hover:after, .breadcrumbs__inner li a:focus:after {
            color: #649530; }
      .breadcrumbs__inner li span {
        display: block;
        color: #649530;
        font-size: 14px; }

/*
* — HELPERS —
*/
/*
* — LAYOUT —
*/
.page-head {
  padding: 15px 0;
  background: #363636; }
  .page-head__inner {
    display: flex;
    justify-content: space-between; }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .page-head__inner {
        justify-content: flex-end; } }
    @media (min-width: 30em) and (max-width: 41.74em) {
      .page-head__inner {
        align-items: center; } }
  .page-head__adress {
    display: flex; }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .page-head__adress {
        display: none; } }
    @media (min-width: 30em) and (max-width: 41.74em) {
      .page-head__adress {
        flex-direction: column; } }
    .page-head__adress p {
      margin: 0 20px 0 0;
      font-size: 14px;
      font-weight: 300;
      color: #fff; }
      .page-head__adress p i {
        margin: 0 5px 0 0;
        font-size: 18px; }
      .page-head__adress p:last-child {
        margin: 0; }
      @media (min-width: 30em) and (max-width: 41.74em) {
        .page-head__adress p {
          margin: 0 0 5px 0; } }

.head_login {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none; }
  .head_login li {
    padding: 0 10px;
    border-right: 1px solid #fff; }
    .head_login li:last-child {
      border-right: none; }
    .head_login li a {
      display: block;
      font-size: 14px;
      font-weight: 300;
      color: #fff;
      text-decoration: none;
      transition: .3s ease-in-out; }
      .head_login li a:hover, .head_login li a:focus {
        text-decoration: none;
        color: #86c60c; }

.page-wrap {
  padding: 30px 0; }
  .page-wrap__inner {
    display: flex;
    justify-content: space-between; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .page-wrap__inner {
        flex-direction: column; } }
  .page-wrap-left {
    width: 25%;
    padding: 0 15px 0 0; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .page-wrap-left {
        width: 100%;
        padding: 0; } }
  .page-wrap-right {
    width: 75%;
    padding: 0 0 0 15px; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .page-wrap-right {
        width: 100%;
        padding: 0; } }

.section-header {
  margin: 0 0 20px 0; }
  .section-header h2 {
    margin: 0;
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    color: #4f4f4f; }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .section-header h2 {
        font-size: 16px; } }

.part-img {
  margin: 0 0 25px 0; }
  .part-img img {
    display: block;
    width: 100%; }

.part-txt {
  margin: 0 0 10px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4f4f4f; }
  .part-txt__ln {
    color: #33b9ff;
    text-decoration: none;
    transition: .2s ease-in-aut; }
    .part-txt__ln:hover {
      color: #33b9ff;
      text-decoration: underline; }

.footer_main {
  padding: 35px 0;
  background: #363636; }
  .footer_main__inner {
    display: flex;
    justify-content: space-between; }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .footer_main__inner {
        flex-direction: column; } }
    @media (min-width: 30em) and (max-width: 41.74em) {
      .footer_main__inner {
        flex-wrap: wrap; } }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .footer_main__item {
      margin: 0 0 20px 0; } }
  @media (min-width: 30em) and (max-width: 41.74em) {
    .footer_main__item {
      width: 50%;
      margin: 0 0 20px 0; } }
  .footer_main__title {
    margin: 0 0 25px 0; }
    @media (min-width: 20em) and (max-width: 81.24em) {
      .footer_main__title {
        margin: 0 0 10px 0; } }
    .footer_main__title h2 {
      margin: 0 0 5px 0;
      line-height: 22px;
      font-size: 17px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 400; }
    .footer_main__title span {
      display: block;
      width: 40px;
      height: 1px;
      background: #4c4c4c; }

.col_inner {
  margin: 0;
  padding: 0;
  list-style: none; }
  .col_inner li {
    margin: 0 0 15px 0; }
    @media (min-width: 20em) and (max-width: 81.24em) {
      .col_inner li {
        margin: 0 0 5px 0; } }
    .col_inner li:last-child {
      margin: 0; }
    .col_inner li a {
      display: block;
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
      color: #a9a9a9;
      cursor: pointer;
      text-decoration: none;
      transition: .2s ease-in-out; }
      .col_inner li a:hover, .col_inner li a:focus {
        text-decoration: underline;
        color: #a9a9a9; }

.footer_contact__item {
  display: flex;
  align-items: center;
  margin: 0 0 18px 0; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .footer_contact__item {
      margin: 0 0 10px 0; } }
  .footer_contact__item:last-child {
    margin: 0; }
  .footer_contact__item span {
    display: block;
    margin: 0 10px 0 0; }
    @media (min-width: 41.75em) and (max-width: 61.24em) {
      .footer_contact__item span {
        display: none; } }
    .footer_contact__item span img {
      display: block; }
  .footer_contact__item div a {
    display: block;
    margin: 0;
    font-size: 16px;
    font-weight: lighter;
    color: #ffffff;
    line-height: 22px;
    text-decoration: none;
    transition: .2s ease-in-aut; }
    .footer_contact__item div a:hover, .footer_contact__item div a:focus {
      color: #86c60c; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .footer_contact__item div a {
        font-size: 14px; } }
  .footer_contact__item div p {
    margin: 0;
    font-size: 14px;
    font-weight: lighter;
    color: #ffffff;
    line-height: 22px; }

.footer_content {
  padding: 20px 0;
  background: #2a2a2a; }
  .footer_content__inner {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .footer_content__inner {
        flex-direction: column;
        align-items: flex-start; } }
    @media (min-width: 30em) and (max-width: 81.24em) {
      .footer_content__inner {
        display: block; } }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .footer_content__item {
      margin: 0 0 15px 0; } }
  @media (min-width: 30em) and (max-width: 81.24em) {
    .footer_content__item {
      margin: 0 0 10px; } }
  .footer_content__item .copyright {
    margin: 0 0 5px 0;
    font-size: 14px;
    color: #fff;
    line-height: 16px;
    font-weight: 300; }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .footer_content__item .copyright {
        text-align: center; } }
    @media (min-width: 30em) and (max-width: 81.24em) {
      .footer_content__item .copyright {
        text-align: center; } }
    .footer_content__item .copyright a {
      color: #fff;
      text-decoration: none; }
      .footer_content__item .copyright a:hover, .footer_content__item .copyright a:focus {
        color: #86c60c;
        text-decoration: none; }
  .footer_content__item .f_inf {
    margin: 0;
    font-size: 14px;
    color: #fff;
    line-height: 16px;
    font-weight: 300; }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .footer_content__item .f_inf {
        margin: 0 0 15px 0;
        line-height: 20px;
        text-align: center; } }
    @media (min-width: 30em) and (max-width: 81.24em) {
      .footer_content__item .f_inf {
        text-align: center; } }
  .footer_content__ln {
    display: flex; }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .footer_content__ln {
        flex-direction: column; } }
    @media (min-width: 30em) and (max-width: 81.24em) {
      .footer_content__ln {
        justify-content: center; } }
  .footer_content__social {
    display: block;
    margin: 0 45px 0 0;
    font-size: 14px;
    color: #d4d4d4;
    line-height: 16px;
    font-weight: 300;
    text-decoration: none;
    transition: .2s ease-in-aut; }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .footer_content__social {
        margin: 0 0 10px 0; } }
    @media (min-width: 30em) and (max-width: 81.24em) {
      .footer_content__social {
        margin: 0 20px 0 0; } }
    .footer_content__social span {
      display: inline-block;
      margin: 0 10px 0 0;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      background: #d4d4d4;
      color: #2a2a2a; }
    .footer_content__social:hover, .footer_content__social:focus {
      color: #86c60c;
      text-decoration: none; }
      .footer_content__social:hover span, .footer_content__social:focus span {
        background: #86c60c; }

.pagination {
  display: block;
  padding: 25px 0;
  margin: 0; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .pagination {
      padding: 10px 0; } }
  .pagination-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none; }
    .pagination-inner li {
      margin: 0 15px 0 0; }
      .pagination-inner li:last-child {
        margin: 0; }

@media (min-width: 20em) and (max-width: 29.99em) {
  .pagination_list {
    display: none; } }

.page-numbers {
  display: block;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: #54657e; }
  .page-numbers:hover, .page-numbers:focus {
    text-decoration: none; }

.page-numbers__item {
  width: 25px;
  height: 25px;
  border: 2px solid transparent;
  text-align: center;
  line-height: 22px; }
  .page-numbers__item:hover, .page-numbers__item:focus {
    border: 2px solid #86c60c; }

.page-numbers__last:hover, .page-numbers__last:focus {
  color: #86c60c; }

.first, .next {
  color: #54657e; }
  .first:hover, .first:focus, .next:hover, .next:focus {
    color: #86c60c; }

.first {
  margin: 0 10px 0 0; }

.next {
  margin: 0 0 0 10px; }

.current {
  width: 25px;
  height: 25px;
  border: 2px solid #86c60c;
  text-align: center;
  line-height: 22px; }

/*
* — PART —
*/
.top-info {
  padding: 30px 0 15px 0;
  background: #fff; }
  .top-info__inner {
    display: flex;
    justify-content: space-between; }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .top-info__inner {
        flex-direction: column;
        align-items: center; } }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .top-info__item {
      margin: 0 0 15px 0; } }

.page-logo img {
  display: block; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .page-logo img {
      margin: 0 auto 15px; } }
  @media (min-width: 30em) and (max-width: 41.74em) {
    .page-logo img {
      width: 80%; } }

.top_contact {
  display: flex;
  align-items: center; }
  @media (min-width: 20em) and (max-width: 61.24em) {
    .top_contact__data {
      display: none; } }
  .top_contact-img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 13px 0 0;
    border-radius: 50%;
    width: 65px;
    height: 65px;
    border: 2px solid #eeeeee;
    border-radius: 2px solid #eeeeee; }
    @media (min-width: 20em) and (max-width: 81.24em) {
      .top_contact-img {
        display: none; } }
    .top_contact-img img {
      display: block; }
  .top_contact-item__phone {
    display: block;
    margin: 0 0 5px 0;
    line-height: 18px;
    font-size: 18px;
    font-weight: 300;
    color: #363636;
    transition: .2s ease-in-out; }
    @media (min-width: 30em) and (max-width: 41.74em) {
      .top_contact-item__phone {
        font-size: 14px; } }
    .top_contact-item__phone:hover, .top_contact-item__phone:focus {
      text-decoration: none;
      color: #0074af; }
  .top_contact-item__order {
    display: inline-block;
    color: #33b9ff;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    text-decoration: none;
    border-bottom: 1px dashed transparent;
    transition: .2s ease-in-out; }
    .top_contact-item__order:hover, .top_contact-item__order:focus {
      text-decoration: none;
      color: #33b9ff;
      border-bottom: 1px dashed #33b9ff; }
  .top_contact-item__data {
    margin: 0 0 5px 0;
    line-height: 18px;
    font-size: 18px;
    line-height: 16px;
    font-weight: 300;
    color: #363636; }
  .top_contact-item__calls {
    display: block;
    color: #363636;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300; }

@media (min-width: 20em) and (max-width: 29.99em) {
  .top_basket {
    margin: 0 auto;
    width: 250px; } }

.top_basket__item {
  display: flex;
  border: 2px solid #eeeeee;
  border-radius: 30px; }
  @media (min-width: 30em) and (max-width: 41.74em) {
    .top_basket__item {
      border: 1px solid #eeeeee;
      border-radius: 4px; } }

.top_basket__ico {
  display: block;
  padding: 5px 15px;
  border-right: 1px solid #eeeeee; }
  @media (min-width: 30em) and (max-width: 41.74em) {
    .top_basket__ico {
      display: none; } }
  .top_basket__ico img {
    display: block;
    margin: auto; }

.top_basket__inf {
  padding: 10px 15px; }
  @media (min-width: 30em) and (max-width: 41.74em) {
    .top_basket__inf {
      padding: 5px; } }
  .top_basket__inf-product {
    margin: 0;
    font-weight: 300;
    font-size: 14px;
    color: #444343;
    line-height: 20px; }
    .top_basket__inf-product span {
      color: #86c60c;
      font-weight: 400; }
    @media (min-width: 30em) and (max-width: 41.74em) {
      .top_basket__inf-product {
        font-size: 13px; } }
  .top_basket__inf-sum {
    margin: 0;
    font-weight: 300;
    font-size: 14px;
    color: #444343;
    line-height: 20px; }
    .top_basket__inf-sum span {
      color: #4c4b4b;
      font-weight: 400; }
    @media (min-width: 30em) and (max-width: 41.74em) {
      .top_basket__inf-sum {
        font-size: 13px; } }

.top-nav {
  position: relative;
  padding: 10px 0;
  background: #fafafa;
  border-top: 1px solid #dce8ee;
  box-shadow: 0px 4px 6px 0px rgba(1, 2, 2, 0.2); }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .top-nav {
      height: 45px; } }
  .top-nav__inner {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.btn-navbar {
  display: none;
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 0;
  color: #393939;
  font-size: 32px;
  line-height: 20px;
  border: none;
  background: transparent; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .btn-navbar {
      display: block;
      top: 0px;
      right: 10px; } }

.nav-list {
  margin: 0; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .nav-list {
      text-align: right; } }
  @media (min-width: 61.25em) {
    .nav-list {
      padding: 10px 0; } }
  .nav-list__inner {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none; }
    .nav-list__inner.active {
      display: block;
      position: absolute;
      padding-left: 15px;
      padding-right: 15px;
      left: 0;
      right: 0;
      z-index: 44;
      background: #fff;
      border-bottom: 2px solid #ae782f; }
      @media (min-width: 20em) and (max-width: 41.74em) {
        .nav-list__inner.active {
          top: 75px; } }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .nav-list__inner {
        display: none;
        text-align: left;
        width: 100%; } }
    .nav-list__inner li {
      margin: 0 45px 0 0; }
      .nav-list__inner li:last-child {
        margin: 0; }
      @media (min-width: 20em) and (max-width: 41.74em) {
        .nav-list__inner li {
          display: block;
          margin: 0;
          padding: 8px 0;
          background: #fff; } }
      @media (min-width: 41.75em) and (max-width: 81.24em) {
        .nav-list__inner li {
          margin: 0 20px 0 0; } }
      .nav-list__inner li a {
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 14px;
        color: #4a4a4a;
        transition: .2s ease-in-out; }
        @media (min-width: 41.75em) and (max-width: 81.24em) {
          .nav-list__inner li a {
            font-size: 12px; } }
        .nav-list__inner li a:hover, .nav-list__inner li a:focus {
          color: #86c60c;
          text-decoration: none; }
  .nav-list_mobile {
    position: relative;
    z-index: 1000; }
    .nav-list_mobile .header-nav__inner {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0; }
      @media (min-width: 20em) and (max-width: 41.74em) {
        .nav-list_mobile .header-nav__inner {
          display: block; } }
      @media (min-width: 46.25em) and (max-width: 61.24em) {
        .nav-list_mobile .header-nav__inner {
          display: flex; } }

@media (min-width: 20em) and (max-width: 61.24em) {
  .nav_search {
    display: none; } }

.product-bar__inner {
  margin: 0 0 35px 0;
  border-bottom: 3px solid #ffd700; }

.product-bar__item {
  margin: 0 !important;
  position: relative; }
  .product-bar__item.active {
    color: #3a3a3a; }
    .product-bar__item.active:after {
      display: block; }
  .product-bar__item:after {
    content: "";
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    width: 100%;
    border-bottom: 3px solid #86c60c !important; }

.product-bar__ln {
  padding: 10px !important;
  font-size: 18px;
  color: #a0a0a0;
  text-transform: uppercase;
  font-weight: 400;
  border: none !important; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .product-bar__ln {
      font-size: 12px; } }
  @media (min-width: 30em) and (max-width: 81.24em) {
    .product-bar__ln {
      font-size: 16px; } }
  .product-bar__ln:hover, .product-bar__ln:focus {
    background: none !important;
    color: #3a3a3a;
    outline: none; }

.productbar-details {
  margin: 0 10px;
  outline: none;
  border: 2px solid #ffd700;
  transition: .2s ease-in-out; }
  .productbar-details:hover, .productbar-details:focus {
    border: 2px solid red; }
  .productbar-details__img {
    margin: 0 0 5px 0; }
    .productbar-details__img img {
      display: block;
      width: 100%; }
  .productbar-details__inf {
    padding: 15px 10px; }
    @media (min-width: 20em) and (max-width: 81.24em) {
      .productbar-details__inf {
        padding: 10px 5px; } }
  .productbar-details__title {
    min-height: 60px;
    margin: 0 0 15px 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #4f4f4f;
    text-align: center;
    text-transform: uppercase; }
    @media (min-width: 20em) and (max-width: 81.24em) {
      .productbar-details__title {
        margin: 0 0 10px 0;
        font-size: 12px; } }
  .productbar-details__price {
    display: block;
    margin: 0 0 20px 0;
    font-size: 22px;
    color: #3a3a3a;
    line-height: 22px;
    font-weight: 500;
    text-align: center; }
    @media (min-width: 20em) and (max-width: 81.24em) {
      .productbar-details__price {
        margin: 0 0 10px 0;
        font-size: 18px; } }
  .productbar-details__btn {
    display: block;
    margin: 0 auto;
    width: 165px;
    height: 45px;
    line-height: 40px;
    border-radius: 5px;
    border: 2px solid #86c60c;
    background: #86c60c;
    text-align: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    outline: none;
    transition: .2s ease-in-out; }
    .productbar-details__btn:hover, .productbar-details__btn:focus {
      color: #86c60c;
      background: #fff;
      text-decoration: none; }
    @media (min-width: 20em) and (max-width: 81.24em) {
      .productbar-details__btn {
        width: 140px;
        height: 35px;
        line-height: 30px;
        font-size: 14px;
        font-weight: 400; } }

.product-bar__slider .slick-arrow {
  display: block;
  position: absolute;
  top: -75px;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 0;
  line-height: 0;
  border: 0;
  z-index: 100;
  outline: none; }

.product-bar__slider .slick-prev {
  right: 40px;
  cursor: pointer;
  background: #ffd700 url(../img/ar-l.png) no-repeat center; }
  .product-bar__slider .slick-prev:hover {
    background: #86c60c url(../img/ar-l.png) no-repeat center; }

.product-bar__slider .slick-next {
  right: 0;
  cursor: pointer;
  background: #ffd700 url(../img/ar-r.png) no-repeat center; }
  .product-bar__slider .slick-next:hover {
    background: #86c60c url(../img/ar-r.png) no-repeat center; }

.section-advantages {
  padding: 85px 0; }
  @media (min-width: 20em) and (max-width: 81.24em) {
    .section-advantages {
      padding: 30px 0; } }
  .section-advantages__inner {
    display: flex;
    justify-content: space-between; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .section-advantages__inner {
        flex-direction: column; } }
  .section-advantages__item {
    width: 33.3%;
    padding: 0 30px; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .section-advantages__item {
        width: 100%;
        margin: 0 0 30px 0;
        padding: 0; } }
    .section-advantages__item div {
      margin: 0 auto 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 140px;
      background: #a3db3a;
      border-radius: 50%; }
      .section-advantages__item div img {
        display: block; }
      @media (min-width: 20em) and (max-width: 81.24em) {
        .section-advantages__item div {
          margin-bottom: 10px;
          width: 125px;
          height: 125px; } }
    .section-advantages__item h2 {
      margin: 0 0 20px 0;
      font-weight: 500;
      font-size: 14px;
      color: #5b5b5b;
      text-align: center;
      text-transform: uppercase; }
      @media (min-width: 20em) and (max-width: 81.24em) {
        .section-advantages__item h2 {
          margin: 0 0 10px 0;
          font-size: 12px; } }
    .section-advantages__item p {
      margin: 0;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      text-align: center;
      color: #5b5b5b; }

.sidebar-categories {
  background: #fafafa;
  border: 1px solid #d3d3d3; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .sidebar-categories {
      margin: 0 0 20px 0;
      border: none;
      background: #fff; } }
  .sidebar-categories__title {
    padding: 10px 5px;
    border-bottom: 1px solid #e9e9e9; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .sidebar-categories__title {
        display: none; } }
    .sidebar-categories__title h3 {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      color: #2f2f2f;
      text-transform: none;
      line-height: 18px; }

@media (min-width: 61.25em) {
  .nav-categories {
    position: relative;
    z-index: 999; } }

.nav-categories__mobile-show {
  display: block;
  margin: 10px auto;
  width: 250px;
  height: 40px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  line-height: 42px;
  text-transform: uppercase;
  color: #fff;
  background: #86c60c; }
  @media (min-width: 41.75em) {
    .nav-categories__mobile-show {
      display: none; } }

.nav-categories__inner {
  margin: 0;
  padding: 0;
  list-style: none; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .nav-categories__inner {
      display: none; }
      .nav-categories__inner.show {
        display: block;
        border-bottom: 1px solid #015aac; } }
  @media (min-width: 41.75em) {
    .nav-categories__inner {
      display: block; } }

.nav-categories__list_second-level {
  margin: 0;
  padding: 0;
  min-width: 230px;
  list-style: none;
  background: #fafafa; }
  @media (min-width: 41.75em) {
    .nav-categories__list_second-level {
      position: absolute;
      z-index: 555;
      top: 0;
      left: 100%;
      border: 1px solid #cccccc; } }
  .nav-categories__list_second-level .nav-categories__ln {
    padding: 10px 5px 10px 10px; }

.nav-categories__list_third-level {
  margin: -1px 0 0 0;
  padding: 0;
  min-width: 230px;
  list-style: none;
  background: #fafafa; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .nav-categories__list_third-level {
      padding-left: 20px; } }
  @media (min-width: 41.75em) {
    .nav-categories__list_third-level {
      position: absolute;
      top: 0;
      left: 100%;
      border: 1px solid #cccccc; } }
  .nav-categories__list_third-level .nav-categories__ln {
    padding: 7px 5px;
    color: #363636; }
    .nav-categories__list_third-level .nav-categories__ln:hover, .nav-categories__list_third-level .nav-categories__ln:focus {
      color: #fff; }
    @media (min-width: 41.75em) {
      .nav-categories__list_third-level .nav-categories__ln {
        padding: 10px 15px 10px 15px; } }

.nav-categories__item_has-child {
  position: relative; }
  .nav-categories__item_has-child > ul {
    display: none; }
  .nav-categories__item_has-child:hover > ul {
    display: block; }

.nav-categories__ln {
  display: block;
  padding: 7px 10px;
  font-size: 16px;
  line-height: 18px;
  font-weight: lighter;
  color: #363636;
  cursor: pointer;
  font-family: 'Open Sans Condensed', sans-serif;
  transition: .2s ease-in-out; }
  @media (min-width: 41.75em) and (max-width: 81.24em) {
    .nav-categories__ln {
      font-size: 13px; } }
  .nav-categories__ln:hover, .nav-categories__ln:focus {
    color: #fff;
    text-decoration: none;
    background: #86c60c; }
  .nav-categories__ln i {
    margin: 0 0 0 5px;
    line-height: 10px;
    font-size: 14px; }

@media (min-width: 20em) and (max-width: 41.74em) {
  .content-slider {
    display: none; } }

.content-slider img {
  display: block;
  width: 100%; }

.content-slider .slick-arrow {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 0;
  line-height: 0;
  border: 0;
  z-index: 100;
  outline: none; }

.content-slider .slick-prev {
  left: 15px;
  cursor: pointer;
  background: rgba(85, 85, 85, 0.6) url(../img/arrow-l.png) no-repeat center; }
  .content-slider .slick-prev:hover {
    background: rgba(85, 85, 85, 0.8) url(../img/arrow-l.png) no-repeat center; }

.content-slider .slick-next {
  right: 15px;
  cursor: pointer;
  background: rgba(85, 85, 85, 0.6) url(../img/arrow-r.png) no-repeat center; }
  .content-slider .slick-next:hover {
    background: rgba(85, 85, 85, 0.8) url(../img/arrow-r.png) no-repeat center; }

.section-contact__inner {
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px 0; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .section-contact__inner {
      flex-direction: column;
      margin: 0 0 40px 0; } }

@media (min-width: 20em) and (max-width: 29.99em) {
  .section-contact__item {
    margin: 0 0 20px 0; } }

.section-contact__item h3 {
  margin: 0 0 15px 0;
  font-size: 16px;
  color: #151219;
  font-weight: 500; }
  @media (min-width: 20em) and (max-width: 81.24em) {
    .section-contact__item h3 {
      margin: 0 0 10px 0; } }

.section-contact__item span {
  display: inline-block;
  margin: 0 0 10px 0;
  font-size: 16px;
  line-height: 18px;
  color: #00aaff;
  border-bottom: 1px dashed #00aaff; }
  @media (min-width: 20em) and (max-width: 81.24em) {
    .section-contact__item span {
      margin: 0 0 5px 0;
      font-size: 14px; } }

.section-contact__item a {
  display: block;
  margin: 0 0 10px 0;
  font-size: 16px;
  line-height: 18px;
  color: #4f4f4f;
  cursor: pointer;
  text-decoration: none;
  transition: .2s ease-in-out; }
  @media (min-width: 20em) and (max-width: 81.24em) {
    .section-contact__item a {
      margin: 0 0 5px 0;
      font-size: 14px; } }
  .section-contact__item a:hover, .section-contact__item a:focus {
    color: #00aaff;
    text-decoration: none; }

.section-contact__item p {
  margin: 0 0 10px 0;
  font-size: 16px;
  line-height: 18px;
  color: #4f4f4f; }
  @media (min-width: 20em) and (max-width: 81.24em) {
    .section-contact__item p {
      margin: 0 0 5px 0;
      font-size: 14px; } }

.contact-map #map {
  width: 100% !important;
  height: 400px !important;
  border: none; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .contact-map #map {
      height: 250px !important; } }

.product-cart {
  display: flex; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .product-cart {
      flex-direction: column;
      margin: 0 0 25px 0; } }
  .product-cart__left {
    width: 40%; }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .product-cart__left {
        width: 100%; } }
  .product-cart__right {
    padding: 0 0 0 50px;
    width: 60%; }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .product-cart__right {
        width: 100%;
        padding: 0; } }
    @media (min-width: 30em) and (max-width: 41.74em) {
      .product-cart__right {
        padding: 0 0 0 15px; } }
    @media (min-width: 46.25em) and (max-width: 81.24em) {
      .product-cart__right {
        padding: 0 0 0 20px; } }

.product-detail__images {
  height: 550px;
  margin: 0 0 30px 0; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .product-detail__images {
      height: 430px; } }
  @media (min-width: 30em) and (max-width: 81.24em) {
    .product-detail__images {
      width: 100%;
      height: 350px;
      margin: 0 auto 20px; } }
  .product-detail__images .detail-img__top {
    margin: 0 0 20px 0; }
    @media (min-width: 20em) and (max-width: 81.24em) {
      .product-detail__images .detail-img__top {
        margin: 0 0 10px 0; } }
    .product-detail__images .detail-img__top .image-big {
      height: 350px;
      border: 2px solid #d3d3d3; }
      @media (min-width: 20em) and (max-width: 29.99em) {
        .product-detail__images .detail-img__top .image-big {
          height: 350px; } }
      @media (min-width: 30em) and (max-width: 41.74em) {
        .product-detail__images .detail-img__top .image-big {
          height: 225px; } }
      @media (min-width: 46.25em) and (max-width: 81.24em) {
        .product-detail__images .detail-img__top .image-big {
          height: 280px; } }
      .product-detail__images .detail-img__top .image-big img {
        display: block;
        width: 100%; }
  .product-detail__images .detail-img__bottom {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .product-detail__images .detail-img__bottom .image-small {
      width: 80px;
      height: 80px;
      border: 2px solid #d3d3d3; }
      @media (min-width: 30em) and (max-width: 81.24em) {
        .product-detail__images .detail-img__bottom .image-small {
          width: 65px;
          height: 65px; } }
      .product-detail__images .detail-img__bottom .image-small img {
        display: block;
        width: 100%; }
  .product-detail__images .slick-arrow {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    margin: 0;
    width: 30px;
    height: 30px;
    font-size: 0;
    line-height: 0;
    border: 0;
    z-index: 100;
    outline: none;
    border: 1px solid #000; }
  .product-detail__images .slick-prev {
    left: -19px;
    background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAMCAMAAACZS1ciAAAAG1BMVEUqKioqKioqKioqKioqKioqKioqKioqKioqKirBokQfAAAACHRSTlMAEXeImaq73QoLrHMAAAAiSURBVHgBYwABRjZWCMXBjItiYOFgg6hl52AizGBjgDAYASYQAL87LcOfAAAAAElFTkSuQmCC) no-repeat center; }
    .product-detail__images .slick-prev:hover, .product-detail__images .slick-prev:active {
      background: #00c9fb url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAMCAMAAACZS1ciAAAAG1BMVEX///////////////////////////////////8HBRbfAAAACHRSTlMAEXeImaq73QoLrHMAAAAiSURBVHgBYwABRjZWCMXBjItiYOFgg6hl52AizGBjgDAYASYQAL87LcOfAAAAAElFTkSuQmCC) no-repeat center;
      border: 1px solid #00c9fb; }
  .product-detail__images .slick-next {
    right: 7px;
    background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAMCAMAAACZS1ciAAAAIVBMVEX///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADdcGRXAAAACnRSTlMAESJViJmqu8zu53CBGAAAACNJREFUCNdjYGFjAAM2LsIMdi5WEMXMxcmEi2LggFAMjBAKAC51AO+YrRq7AAAAAElFTkSuQmCC) no-repeat center; }
    .product-detail__images .slick-next:hover, .product-detail__images .slick-next:active {
      background: #00c9fb url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAMCAMAAACZS1ciAAAAIVBMVEX///////////////////////////////////////////9/gMdvAAAACnRSTlMAESJViJmqu8zu53CBGAAAACNJREFUCNdjYGFjAAM2LsIMdi5WEMXMxcmEi2LggFAMjBAKAC51AO+YrRq7AAAAAElFTkSuQmCC) no-repeat center;
      border: 1px solid #00c9fb; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .product-detail__images .slick-next {
        right: -10px; } }

.product-cart__title {
  margin: 0 0 15px 0; }
  .product-cart__title h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #4f4f4f;
    text-transform: uppercase; }
    @media (min-width: 20em) and (max-width: 81.24em) {
      .product-cart__title h2 {
        font-size: 16px;
        line-height: 20px; } }

.product-cart__reference {
  display: block;
  margin: 0 0 20px 0;
  font-weight: 400;
  font-size: 14px;
  color: #565656;
  line-height: 16px; }
  @media (min-width: 20em) and (max-width: 81.24em) {
    .product-cart__reference {
      margin: 0 0 15px 0;
      font-size: 14px; } }

.product-cart__availability {
  display: block;
  position: relative;
  margin: 0 0 20px 0;
  padding: 0 0 0 15px;
  font-weight: 400;
  font-size: 16px;
  color: #86c60c;
  line-height: 16px; }
  .product-cart__availability:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #86c60c; }
  @media (min-width: 20em) and (max-width: 81.24em) {
    .product-cart__availability {
      margin: 0 0 15px 0;
      font-size: 14px; } }

.product-cart__price {
  display: block;
  margin: 0 0 30px 0;
  font-size: 24px;
  font-weight: 400;
  color: #1b2943;
  line-height: 26px; }
  @media (min-width: 20em) and (max-width: 81.24em) {
    .product-cart__price {
      margin: 0 0 20px 0;
      font-size: 20px; } }

.product-information {
  margin: 0 0 50px 0; }
  .product-information__inner {
    border: none; }
  .product-information__item {
    margin-bottom: -2px !important;
    background: #fff; }
  .product-information__ln {
    margin: 0 !important;
    color: #86c60c !important;
    font-size: 16px;
    font-weight: 400 !important;
    text-transform: uppercase;
    border: 2px solid #e9e9e9 !important;
    border-bottom-color: transparent !important;
    border-radius: 0 !important; }
  .product-information__tab {
    padding: 15px;
    border: 2px solid #e9e9e9; }
    .product-information__tab-1 p {
      margin: 0 0 25px 0;
      font-size: 14px;
      color: #1b2943;
      font-weight: 400;
      line-height: 22px; }
    .product-information__tab-1 h2 {
      margin: 0 0 20px;
      font-weight: 500;
      font-size: 16px;
      color: #1b2943; }
    .product-information__tab-1 ul {
      margin: 0;
      padding: 0 0 0 10px;
      list-style: none; }
      .product-information__tab-1 ul li {
        position: relative;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #1b2943; }
        .product-information__tab-1 ul li:before {
          content: "";
          position: absolute;
          top: 10px;
          left: -8px;
          width: 3px;
          height: 3px;
          background: #1b2943;
          border-radius: 50%; }

.section-order {
  margin: 0 0 50px 0; }
  .section-order .section-title {
    padding: 30px 0 0 0; }
    .section-order .section-title h2 {
      margin: 0;
      font-size: 18px;
      line-height: 20px;
      text-transform: uppercase;
      color: #4f4f4f; }

.order-form .order_list__item {
  display: flex;
  align-items: center;
  margin: 0 0 30px 0; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .order-form .order_list__item {
      flex-direction: column;
      align-items: flex-start; } }

.order-form .order_list__label {
  margin: 0;
  width: 220px;
  font-size: 14px;
  color: #595959;
  line-height: 16px;
  font-weight: 400; }
  .order-form .order_list__label span {
    color: #ef0000; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .order-form .order_list__label {
      width: 100%;
      margin: 0 0 10px 0; } }

.order-form .order_list__input {
  display: block;
  width: 360px;
  height: 40px;
  padding: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #999999;
  background: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 0;
  outline: none; }
  .order-form .order_list__input:focus {
    color: #0085c3;
    outline: none;
    border: 1px solid #0085c3; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .order-form .order_list__input {
      width: 290px; } }

.order-form .order_list__textarea {
  display: block;
  width: 360px;
  height: 160px;
  padding: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #999999;
  background: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 0;
  outline: none;
  resize: none; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .order-form .order_list__textarea {
      width: 290px; } }

.order-form__submit {
  text-align: right; }

.order-form__btn {
  text-align: center;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  width: 200px;
  height: 40px;
  line-height: 38px;
  background: #86c60c;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border: 2px solid #86c60c;
  border-radius: 5px;
  outline: none;
  transition: 0.2s ease-in-out; }
  .order-form__btn:hover, .order-form__btn:focus {
    background: #fff;
    color: #86c60c; }

.order-table {
  min-width: 100%;
  margin: 0;
  min-height: .01%;
  overflow-x: auto; }
  .order-table table {
    width: 100%;
    max-width: 100%; }
    .order-table table thead {
      border-bottom: 2px solid #5ec700; }
      .order-table table thead tr th {
        padding: 10px 5px;
        font-size: 14px;
        font-weight: 500;
        color: #000000; }
    .order-table table tbody tr {
      border-bottom: 1px solid #cad3df; }
      .order-table table tbody tr td {
        padding: 10px 20px;
        white-space: nowrap; }
  .order-table__img img {
    display: block; }
  .order-table__title {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #3a3a3a;
    text-transform: uppercase; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .order-table__title {
        font-size: 12px; } }
  .order-table__quantity {
    display: block;
    font-weight: 400;
    font-size: 14px;
    color: #616161; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .order-table__quantity {
        font-size: 12px; } }
  .order-table__price {
    display: block;
    font-size: 18px;
    color: #000000;
    text-align: left;
    font-weight: 500; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .order-table__price {
        font-size: 14px; } }

.amount-calc {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 30px 0; }
  .amount-calc table {
    background: #eceff4;
    border-radius: 0 0 5px 5px; }
    .amount-calc table tr td {
      padding: 5px 15px;
      font-weight: 400;
      font-size: 16px; }
      @media (min-width: 20em) and (max-width: 41.74em) {
        .amount-calc table tr td {
          padding: 3px 10px;
          font-size: 12px; } }

.section-catolog__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .section-catolog__inner {
      flex-direction: column; } }
  @media (min-width: 30em) and (max-width: 41.74em) {
    .section-catolog__inner {
      flex-wrap: wrap; } }

.section-catolog__item {
  width: 31%;
  margin: 0 0 30px 0;
  border: 2px solid #ffd700;
  transition: .2s ease-in-out; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .section-catolog__item {
      width: 100%; } }
  @media (min-width: 30em) and (max-width: 41.74em) {
    .section-catolog__item {
      width: 49%; } }
  .section-catolog__item:hover {
    border: 2px solid #86c60c; }

.section-catolog__img {
  margin: 0 0 5px 0; }
  .section-catolog__img img {
    display: block;
    margin: 0 auto;
    width: 100%; }

.section-catolog__inf {
  padding: 15px 10px; }
  @media (min-width: 20em) and (max-width: 81.24em) {
    .section-catolog__inf {
      padding: 10px 5px; } }

.section-catolog__title {
  margin: 0 0 15px 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #4f4f4f;
  text-align: center;
  text-transform: uppercase; }
  @media (min-width: 20em) and (max-width: 81.24em) {
    .section-catolog__title {
      margin: 0 0 10px 0;
      font-size: 12px; } }

.section-catolog__price {
  display: block;
  margin: 0 0 20px 0;
  font-size: 22px;
  color: #3a3a3a;
  line-height: 22px;
  font-weight: 500;
  text-align: center; }
  @media (min-width: 20em) and (max-width: 81.24em) {
    .section-catolog__price {
      margin: 0 0 10px 0;
      font-size: 18px; } }

.section-catolog__btn {
  display: block;
  margin: 0 auto;
  width: 165px;
  height: 45px;
  line-height: 40px;
  border-radius: 5px;
  border: 2px solid #86c60c;
  background: #86c60c;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  transition: .2s ease-in-out; }
  .section-catolog__btn i {
    margin: 0 5px 0 0; }
  .section-catolog__btn:hover, .section-catolog__btn:focus {
    color: #86c60c;
    background: #fff;
    text-decoration: none; }
  @media (min-width: 20em) and (max-width: 81.24em) {
    .section-catolog__btn {
      width: 140px;
      height: 35px;
      line-height: 30px;
      font-size: 14px;
      font-weight: 400; } }

.seo-text {
  padding: 50px 0;
  background: #f4f4f4; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .seo-text {
      padding: 30px 0; } }
  .seo-text p {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    color: #707070; }

.request-callback .modal-title {
  margin: 0 0 40px;
  font-size: 24px;
  line-height: 24px;
  text-transform: none;
  font-weight: 400;
  text-align: left;
  color: #313030; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .request-callback .modal-title {
      margin: 0 0 20px 0;
      font-size: 18px; } }

.request-callback .popup {
  max-width: 560px;
  min-height: 320px;
  padding: 30px;
  border-radius: 1px; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .request-callback .popup {
      padding: 15px 10px; } }

.modal-basket .modal-title {
  margin: 0 0 40px;
  font-size: 24px;
  line-height: 24px;
  text-transform: none;
  font-weight: 400;
  text-align: left;
  color: #313030; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .modal-basket .modal-title {
      margin: 0 0 20px 0;
      font-size: 18px; } }

.modal-basket .popup {
  max-width: 950px;
  min-height: 320px;
  padding: 20px 35px;
  border-radius: 1px; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .modal-basket .popup {
      padding: 15px 25px; } }

.modal-basket .basket-table {
  min-width: 100%;
  margin: 0;
  min-height: .01%;
  overflow-x: auto; }
  .modal-basket .basket-table table {
    width: 100%;
    max-width: 100%; }
  .modal-basket .basket-table thead {
    border-bottom: 2px solid #5ec700; }
    .modal-basket .basket-table thead tr th {
      padding: 10px 10px;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      white-space: nowrap; }
  .modal-basket .basket-table tbody tr {
    border-bottom: 1px solid #cad3df; }
    .modal-basket .basket-table tbody tr td {
      padding: 5px 15px;
      white-space: nowrap; }
  .modal-basket .basket-table__img img {
    display: block; }
  .modal-basket .basket-table__title {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3a3a3a;
    text-transform: uppercase; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .modal-basket .basket-table__title {
        font-size: 10px; } }
  .modal-basket .basket-table__trash i {
    font-size: 16px;
    text-align: center;
    color: #cad3df;
    cursor: pointer; }
    .modal-basket .basket-table__trash i:hover, .modal-basket .basket-table__trash i:focus {
      color: #b8b9ba; }

.modal-basket .basket-quantity {
  display: flex;
  margin: 0 35px 0 0; }
  .modal-basket .basket-quantity__minus, .modal-basket .basket-quantity__plus {
    width: 30px;
    height: 45px;
    line-height: 36px;
    background: #fff;
    border: 1px solid #dddddd;
    font-size: 18px;
    font-weight: 400;
    color: #586077;
    text-align: center;
    white-space: nowrap;
    outline: none;
    cursor: pointer; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .modal-basket .basket-quantity__minus, .modal-basket .basket-quantity__plus {
        width: 25px;
        height: 30px;
        line-height: 25px;
        font-size: 16px; } }
    .modal-basket .basket-quantity__minus:first-child, .modal-basket .basket-quantity__plus:first-child {
      border-radius: 5px 0 0 5px; }
    .modal-basket .basket-quantity__minus:last-child, .modal-basket .basket-quantity__plus:last-child {
      border-radius: 0 5px 5px 0; }
  .modal-basket .basket-quantity__input {
    width: 40px;
    height: 45px;
    font-size: 14px;
    text-align: center;
    color: #586077;
    font-weight: 500;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    border-left: none;
    border-right: none;
    outline: none; }
    @media (min-width: 20em) and (max-width: 41.74em) {
      .modal-basket .basket-quantity__input {
        width: 20px;
        height: 30px;
        line-height: 25px; } }

.modal-basket .basket-info {
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px 0; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .modal-basket .basket-info {
      flex-direction: column; } }
  .modal-basket .basket-info__left {
    padding: 10px 0 0 0; }
    .modal-basket .basket-info__left p {
      margin: 0;
      font-size: 14px;
      font-weight: 300;
      color: #888888;
      line-height: 22px; }
      @media (min-width: 20em) and (max-width: 41.74em) {
        .modal-basket .basket-info__left p {
          font-size: 12px;
          line-height: 20px; } }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .modal-basket .basket-info__right {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eceff4; } }
  .modal-basket .basket-info__right table {
    background: #eceff4;
    border-radius: 0 0 5px 5px; }
    .modal-basket .basket-info__right table tr td {
      padding: 3px 15px;
      font-weight: 400;
      font-size: 16px; }
      @media (min-width: 20em) and (max-width: 41.74em) {
        .modal-basket .basket-info__right table tr td {
          padding: 3px 10px;
          font-size: 12px; } }

.modal-basket .form-basket__submit {
  display: flex;
  justify-content: flex-end; }

.modal-basket .form-basket__btn {
  display: block;
  width: 200px;
  height: 40px;
  line-height: 35px;
  border: 2px solid #86c60c;
  border-radius: 5px;
  background: #86c60c;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  outline: none;
  text-transform: uppercase;
  transition: .2s ease-in-out; }
  .modal-basket .form-basket__btn:hover, .modal-basket .form-basket__btn:focus {
    background: #fff;
    color: #86c60c; }

/*
* — PLUGINS —
*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.order_list__item .custom-select-menu {
  display: inline-block;
  outline: none;
  position: relative; }
  .order_list__item .custom-select-menu label {
    display: block;
    margin: 0;
    position: relative;
    user-select: none;
    width: 360px;
    height: 40px;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #afafaf;
    cursor: pointer;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d3d3d3;
    border-radius: 0; }
    .order_list__item .custom-select-menu label:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -3px;
      width: 8px;
      height: 8px;
      border-bottom: 1px solid #464646;
      border-right: 1px solid #464646;
      transform: rotate(45deg) translateY(-50%);
      transition: all .4s ease-in-out;
      transform-origin: 50% 0; }
    .order_list__item .custom-select-menu label:focus {
      color: #0085c3;
      outline: none;
      border: 1px solid #0085c3;
      box-shadow: 0 2px 3px rgba(58, 132, 223, 0.3); }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .order_list__item .custom-select-menu label {
        width: 290px;
        margin: auto; } }

.order_list__item .custom-select-menu label.opened {
  border-radius: 0; }

.order_list__item .custom-select-menu label.opened:after {
  margin-top: 3px;
  transform: rotate(-135deg) translateY(-50%); }

.order_list__item .custom-select-menu ul {
  margin: -5px 0 0 0;
  padding: 0;
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 4;
  width: 100%;
  background: #fff;
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  border-radius: 0;
  list-style: none;
  overflow-x: hidden;
  overflow-y: scroll; }
  .order_list__item .custom-select-menu ul li {
    cursor: pointer;
    padding: 10px;
    font-size: 14px;
    color: #777777;
    background: #fff;
    text-align: left; }
    .order_list__item .custom-select-menu ul li:hover, .order_list__item .custom-select-menu ul li:focus {
      background: #5ec700;
      color: #fff; }

.overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  position: fixed;
  cursor: default;
  transition: all 0.6s; }
  .overlay:target {
    visibility: visible;
    opacity: 1; }

.modal-title {
  margin: 0 0 40px;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
  color: #313030; }
  @media (min-width: 20em) and (max-width: 41.74em) {
    .modal-title {
      margin: 0 0 20px 0;
      font-size: 18px; } }

/* стили модального окна */
.popup {
  top: 0;
  right: 0;
  left: 50%;
  font-size: 14px;
  z-index: 10000;
  width: 100%;
  max-width: 520px;
  min-height: 320px;
  position: fixed;
  padding: 30px 60px;
  border: 1px solid #383838;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.22), 0 19px 60px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -150%);
  transition: transform 0.6s ease-out; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .popup {
      width: 90%;
      padding: 30px 10px;
      border-radius: 0; } }
  @media (min-width: 30em) and (max-width: 41.74em) {
    .popup {
      width: 90%;
      padding: 30px 10px;
      border-radius: 0;
      overflow-x: auto; } }
  @media (min-width: 46.25em) and (max-width: 61.24em) {
    .popup {
      width: 90%; } }

.overlay:target + .popup {
  top: 50%;
  transform: translate(-50%, -50%); }

/* формируем кнопку закрытия */
.close {
  position: absolute;
  padding: 0;
  top: 2px;
  right: 5px;
  width: 18px;
  height: 24px;
  border: none;
  border-radius: none;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  line-height: 28px;
  opacity: 1;
  transition: background-color ease .7s; }
  @media (min-width: 20em) and (max-width: 29.99em) {
    .close {
      width: 25px;
      height: 25px;
      line-height: 15px;
      top: 5px;
      right: 5px;
      border-radius: 0; } }
  .close:before {
    color: #363636;
    content: '\2715';
    font-size: 20px;
    font-weight: 700; }
    @media (min-width: 20em) and (max-width: 29.99em) {
      .close:before {
        font-size: 18px; } }
  .close:hover {
    opacity: 1; }
    .close:hover:before {
      color: #86c60d; }
