.section-catolog{
	&__inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@include mq($from: mobile, $until: mobileVertical){
			flex-direction: column;
		}
		@include mq($from: mobileVertical, $until: mobileHorizontal){
			flex-wrap: wrap;
		}
	}
	&__item{
		width: 31%;
		margin: 0 0 30px 0;
		border: 2px solid #ffd700;
		transition: .2s ease-in-out;
		@include mq($from: mobile, $until: mobileVertical){
			width: 100%;
		}
		@include mq($from: mobileVertical, $until: mobileHorizontal){
			width: 49%;
		}
		&:hover{
			border: 2px solid #86c60c;
		}
	}
	&__img{
		margin: 0 0 5px 0;
		img{
			display: block;
			margin: 0 auto;
			width: 100%;
		}
	}
	&__inf{
		padding: 15px 10px;
		@include mq($from: mobile, $until: wide){
			padding: 10px 5px;
		}
	}
	&__title{
		margin: 0 0 15px 0;
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;
		color: #4f4f4f;
		text-align: center;
		text-transform: uppercase;
		@include mq($from: mobile, $until: wide){
			margin: 0 0 10px 0;
			font-size: 12px;
		}
	}
	&__price{
		display: block;
		margin: 0 0 20px 0;
		font-size: 22px;
		color: #3a3a3a;
		line-height: 22px;
		font-weight: 500;
		text-align: center;
		@include mq($from: mobile, $until: wide){
			margin: 0 0 10px 0;
			font-size: 18px;
		}
	}
	&__btn{
		display: block;
		margin:  0 auto;
		width: 165px;
		height: 45px;
		line-height: 40px;
		border-radius: 5px;
		border: 2px solid #86c60c;
		background: #86c60c;
		text-align: center;
		color: #fff;
		text-decoration: none;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 500;
		transition: .2s ease-in-out;
		i{
			margin: 0 5px 0 0;
		}
		&:hover, &:focus{
			color: #86c60c;
			background: #fff;
			text-decoration: none;
		}
		@include mq($from: mobile, $until: wide){
			width: 140px;
			height: 35px;
			line-height: 30px;
			font-size: 14px;
			font-weight: 400;
		}
	}
}