.page-footer{
	
}

.footer_main{
	padding: 35px 0;
	background: #363636;
	&__inner{
		@extend .container;
		display: flex;
		justify-content: space-between;
		@include mq($from: mobile, $until: mobileVertical){
			flex-direction: column;
		}
		@include mq($from: mobileVertical, $until: mobileHorizontal){
			flex-wrap: wrap;
		}
	}
	&__item{
		@include mq($from: mobile, $until: mobileVertical){
			margin: 0 0 20px 0;
		}
		@include mq($from: mobileVertical, $until: mobileHorizontal){
			width: 50%;
			margin: 0 0 20px 0;
		}
	}
	&__title{
		margin: 0 0 25px 0;
		@include mq($from: mobile, $until: wide){
			margin: 0 0 10px 0;
		}
		h2{
			margin: 0 0 5px 0;
			line-height: 22px;
			font-size: 17px;
			color: #fff;
			text-transform: uppercase;
			font-weight: 400;
		}
		span{
			display: block;
			width: 40px;
			height: 1px;
			background: #4c4c4c;
		}
	}
}

.col_inner{
	margin: 0;
	padding: 0;
	list-style: none;
	li{
		margin: 0 0 15px 0;
		@include mq($from: mobile, $until: wide){
			margin: 0 0 5px 0;
		}
		&:last-child{
			margin: 0;
		}
		a{
			display: block;
			font-size: 14px;
			line-height: 16px;
			font-weight: 300;
			color: #a9a9a9;
			cursor: pointer;
			text-decoration: none;
			transition: .2s ease-in-out;
			&:hover, &:focus{
				text-decoration: underline;
				color: #a9a9a9;
			}
		}
	}
}
.footer_contact{
	&__item{
		display: flex;
		align-items: center;
		margin: 0 0 18px 0;
		@include mq($from: mobile, $until: mobileHorizontal){
			margin: 0 0 10px 0;
		}
		&:last-child{
			margin: 0;
		}
		span{
			display: block;
			margin: 0 10px 0 0;
			@include mq($from: mobileHorizontal, $until: desktop){
				display: none;
			}
			img{
				display: block;
			}
		}
		div{
			a{
				display: block;
				margin: 0;
				font-size: 16px;
				font-weight: lighter;
				color: #ffffff;
				line-height: 22px;
				text-decoration: none;
				transition: .2s ease-in-aut;
				&:hover, &:focus{
					color: #86c60c;
				}
				@include mq($from: mobile, $until: mobileHorizontal){
					font-size: 14px;
				}
			}
			p{
				margin: 0;
				font-size: 14px;
				font-weight: lighter;
				color: #ffffff;
				line-height: 22px;
			}
		}
	}
}
.footer_content{
	padding: 20px 0;
	background: #2a2a2a;
	&__inner{
		@extend .container;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include mq($from: mobile, $until: mobileVertical){
			flex-direction: column;
			align-items: flex-start;
		}
		@include mq($from: mobileVertical, $until: wide){
			display: block;

		}
	}
	&__item{
		@include mq($from: mobile, $until: mobileVertical){
			margin: 0 0 15px 0;
		}
		@include mq($from: mobileVertical, $until: wide){
			margin: 0 0 10px;
		}
		.copyright{
			margin: 0 0 5px 0;
			font-size: 14px;
			color: #fff;
			line-height: 16px;
			font-weight: 300;
			@include mq($from: mobile, $until: mobileVertical){
				text-align: center;
			}
			@include mq($from: mobileVertical, $until: wide){
				text-align: center;
			}
			a{
				color: #fff;
				text-decoration: none;
				&:hover,&:focus{
					color: #86c60c;
					text-decoration: none;
				}
			}
		}
		.f_inf{
			margin: 0;
			font-size: 14px;
			color: #fff;
			line-height: 16px;
			font-weight: 300;
			@include mq($from: mobile, $until: mobileVertical){
				margin: 0 0 15px 0;
				line-height: 20px;
				text-align: center;
			}
			@include mq($from: mobileVertical, $until: wide){
				text-align: center;
			}
		}
	}
	&__ln{
		display: flex;
		@include mq($from: mobile, $until: mobileVertical){
			flex-direction: column;
		}
		@include mq($from: mobileVertical, $until: wide){
			    justify-content: center;
		}
	}
	&__social{
		display: block;
		margin: 0 45px 0 0;
		font-size: 14px;
		color: #d4d4d4;
		line-height: 16px;
		font-weight: 300;
		text-decoration: none;
		transition: .2s ease-in-aut;
		@include mq($from: mobile, $until: mobileVertical){
			margin: 0 0 10px 0;
		}
		@include mq($from: mobileVertical, $until: wide){
			margin: 0 20px 0 0;
		}
		span{
			display: inline-block;
			margin: 0 10px 0 0;
			width: 30px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			border-radius: 50%;
			background: #d4d4d4;
			color: #2a2a2a;
			i{

			}
		}
		&:hover, &:focus{
			color: #86c60c;
			text-decoration: none;
			span{
				background: #86c60c;
			}
		}
		
	}
}