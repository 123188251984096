.content-slider{
    @include mq($from: mobile, $until: mobileHorizontal){
        display: none;
    }
    img{
        display: block;
        width: 100%;
    }
    .slick-arrow{
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
        margin: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        font-size: 0;
        line-height: 0;
        border: 0;
        z-index: 100; 
        outline: none;
    }
    .slick-prev{
        left: 15px;
        cursor: pointer;
        background: rgba(85, 85, 85, 0.6) url(../img/arrow-l.png) no-repeat center;
		&:hover{
			background: rgba(85, 85, 85, 0.8) url(../img/arrow-l.png) no-repeat center;;
		}
    }
    .slick-next{
        right: 15px;
        cursor: pointer;
		background: rgba(85, 85, 85, 0.6) url(../img/arrow-r.png) no-repeat center;
    	&:hover{
    		background: rgba(85, 85, 85, 0.8) url(../img/arrow-r.png) no-repeat center;
    	}
    }
}